import React, { useEffect, useState } from 'react';
import './App.css';
import { HashRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { EventBus, useEventBus } from "./lib/EventBus";
import apiFor from "./api/Api";
import { Apis } from "./api/Config";
import { Info, User } from "./api/models/Vivi";
import MapBoxContainer from "./pages/MapBoxContainer";
import GoogleMapContainer from "./pages/GoogleMapContainer";
import StripeProvider from "./lib/StripeProvider";
import UserContext from "./lib/context/UserContext";
import { Stack } from "@mui/material";
import TokenContext from "./lib/context/TokenContext";
import Main from "./pages/Main";
import InfoContext from "./lib/context/InfoContext";

declare global {
  interface Window {
    eventBus: EventBus
    map: google.maps.Map
    ApplePaySession?: any
    webkit?: any
    _env_: { VIVI_SERVER_URL: string }
  }
}

const App = ({ token }: { token: string }) => {
  window.eventBus = useEventBus();
  const [ info, setInfo ] = useState<Info | undefined>()
  const [ user, setUser ] = useState<User | undefined>()

  useEffect(() => {
    apiFor(Apis.Vivi.Info, { token }).get({
      fields: [ '*', 'alerts.type', 'alerts.message', 'versions.type', 'versions.semVer' ]
    }).then((info) => {
      setInfo(info);
      apiFor(Apis.Vivi.User, { token }).get({ id: token }).then(profile => {
        setUser(profile);
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ token ]);

  const useMapbox = window.location.search === '?map=mapbox';

  return (<TokenContext.Provider value={token}>
    {info && user
      ? <InfoContext.Provider value={info}>
        <UserContext.Provider value={user}>
          <StripeProvider
            stripePubKey="pk_test_51NL6U3L5T00wg3nTMfplDlu8leJO90gLBzqgcVbYvEQtQK2eXSfzOizrJoq1hCz3K5eUrSOdjHMcDctpAqPxANZV00rdo9IgEV">
            <SnackbarProvider style={{ zIndex: 99999999 }} anchorOrigin={{ horizontal: 'center', vertical: "top" }}>
              <HashRouter>
                {
                  useMapbox
                    ? info &&
                    <MapBoxContainer token={token}><Main/></MapBoxContainer>
                    : info &&
                    <GoogleMapContainer token={token}><Main/></GoogleMapContainer>
                }
              </HashRouter>
            </SnackbarProvider>
          </StripeProvider>
        </UserContext.Provider>
      </InfoContext.Provider>
      : <Stack sx={{ backgroundColor: "#FFFF57" }} alignItems={"center"} justifyContent={"center"} height={"100%"}>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="160px" height="160px"
             viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
          <metadata>Created by potrace 1.14, written by Peter Selinger 2001-2017</metadata>
          <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path
              d="M2970 7800 c-292 -41 -590 -224 -751 -461 -56 -83 -115 -209 -141 -304 -18 -68 -22 -106 -22 -245 0 -150 3 -173 28 -255 14 -49 44 -127 66 -172 42 -89 2362 -4123 2437 -4238 56 -85 187 -222 268 -280 292 -208 669 -236 1012 -75 227 106 435 316 525 530 111 267 100 569 -32 831 -58 114 -2412 4194 -2455 4254 -50 70 -189 207 -258 254 -189 129 -455 192 -677 161z"></path>
            <path
              d="M6810 7804 c-183 -21 -322 -60 -463 -130 -138 -68 -223 -130 -337 -244 -192 -192 -304 -403 -357 -668 -23 -119 -23 -356 1 -477 80 -418 281 -798 908 -1719 227 -333 249 -356 348 -356 63 0 118 27 157 77 51 65 445 649 565 838 429 673 573 1056 555 1469 -25 576 -449 1070 -1016 1186 -95 19 -290 32 -361 24z"></path>
          </g>
        </svg>
      </Stack>
    }
  </TokenContext.Provider>);
}

export default App;
