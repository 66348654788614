import Menu from "../components/Menu";
import { Box, Button, Stack } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import SettingsPage from "./SettingsPage";
import SearchPage from "./SearchPage";
import SuggestionPage from "./SuggestionPage";
import ResultsPage from "./ResultsPage";
import TripPage from "./TripPage";
import React, { useState } from "react";
import OrderPage from "./OrderPage";
import { useUser } from "../lib/context/UserContext";
import { useInfo } from "../lib/context/InfoContext";
import BottomSheet from "../components/BottomSheet";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";
import { Version, VersionType } from "../api/models/Vivi";
import { useToken } from "../lib/context/TokenContext";
import NotificationHandler from "../components/NotificationHandler";

const Main = () => {
  const info = useInfo();
  const token = useToken();
  const profile = useUser();
  const [ loading, setLoading ] = useState(false);
  const profileApi = apiFor(Apis.Vivi.User, { token })

  if (profile.email) {
    document.head.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#ffffff");
  } else {
    document.head.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#0A0637");
  }

  return (
    <div style={{ position: "absolute" }}>
      {info.needsToSAcceptance ?
        <BottomSheet disableDrag disableClose disableBack detent="full-height">
          <Stack spacing="1em" padding="1em" height="100%" justifyContent="space-between">
            <Box flexGrow={1}>
              <Stack justifyContent="center" height="100%">
                <h1>In order to use vivi you must accept our Terms of Service</h1>
              </Stack>
            </Box>
            <Button disabled={loading} variant="contained" onClick={() => {
              setLoading(true);
              profileApi.update({
                id: profile.id!,
                obj: { acceptedTos: (info.versions as Version[])?.find(v => v.type === VersionType.ToS)?.semVer }
              })
                .then(() => window.location.reload())
                .catch(() => setLoading(false))
            }}>Accept</Button>
          </Stack>
        </BottomSheet>
        : <>
          <NotificationHandler/>
          <Menu/>
          <Box>
            <Routes>
              <Route path={"/settings/:section"} element={<SettingsPage/>}/>
              <Route path={"/search"} element={
                <SearchPage/>
              }/>
              <Route path={"/suggestions"} element={<SuggestionPage/>}/>
              <Route path={"/searches/:id"} element={
                <SearchPage/>
              }/>
              <Route path={"/results/:id"} element={<ResultsPage/>}/>
              <Route path={"/trips/:id"} element={<TripPage/>}/>
              <Route path={"/orders/:id"} element={<OrderPage/>}/>
              <Route path="*" element={<Navigate to="/suggestions" replace/>}/>
              <Route path="/settings" element={<Navigate to="/settings/history" replace/>}/>
            </Routes>
          </Box>
        </>
      }
    </div>
  );
};

export default Main;