import { Apis } from "../../api/Config";
import { CircularProgress, FormControl, FormControlLabel, Stack, Switch } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { SortDirection } from "../../api/Search";
import { useToken } from "../../lib/context/TokenContext";
import apiFor from "../../api/Api";
import { useUser } from "../../lib/context/UserContext";
import { RailCard } from "../../api/models/Vivi";

const RailCards = () => {
  const token = useToken();
  const user = useUser();

  const [ loading, setLoading ] = useState(true);
  const [ ready, setReady ] = useState(false);
  const [ railCards, setRailCards ] = useState<RailCard[]>([]);
  const [ userRailCards, setUserRailCards ] = useState<{ [key: string]: boolean }>({});

  const railCardApi = apiFor(Apis.Vivi.RailCard, { token });
  const userApi = apiFor(Apis.Vivi.User, { token });

  useMemo(() => {
    Promise.all([
      railCardApi.list({
        fields: [
          'name'
        ],
        sort: [
          { field: 'name', direction: SortDirection.Asc }
        ],
        pageSize: 50
      }),
      userApi.get({ id: user.id })
    ]).then(([ page, user ]) => {
      setRailCards(page.content)
      setUserRailCards(
        page?.content.reduce(
          (c, r) => Object.assign(c, { [r.id!]: ((user.railCards as string[]).indexOf(r.id!) !== -1) }),
          {} as { [key: string]: boolean }
        )
      );
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      setReady(true);
      if (ready) {
        userApi.update({
          id: user.id!,
          obj: { railCards: Object.keys(userRailCards).filter(i => userRailCards[i]) }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userRailCards ])

  return loading
    ? (
      <Stack alignItems={"center"} justifyContent={"center"}>
        <CircularProgress/>
      </Stack>
    )
    : <Stack spacing={"1em"} overflow={"auto"} maxHeight={"86vh"} paddingRight={"1em"} paddingBottom={'1em'}>
      {
        railCards?.map((railCard) =>
          <FormControl key={railCard.id!} id={railCard.id!} fullWidth>
            <FormControlLabel
              control={
                <Switch
                  name={`enabled`}
                  checked={userRailCards[railCard.id!]}
                  onChange={() => {
                    setUserRailCards({
                      ...userRailCards,
                      [railCard.id!]: !userRailCards[railCard.id!]
                    });
                  }}
                />
              }
              label={railCard.name}
              labelPlacement="start"
              style={{ justifyContent: "space-between" }}
            />
          </FormControl>
        )}
    </Stack>;
};

export default RailCards;
