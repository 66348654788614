import React, { ReactElement, useState } from 'react'
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { LatLng, MapApi, MarkerType, Polyline } from "../lib/MapApi";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import GoogleApisContext from "../lib/context/GoogleApisContext";

const libraries: Libraries = [ 'places', 'geocoding' ];

// const mapBottomOffset = 400;

function MapBoxContainer({ token, children }: { token: string, children: ReactElement }) {
  const { isLoaded } = useJsApiLoader({
    libraries,
    googleMapsApiKey: "AIzaSyDlDV-24MV-ks32VqzgYV58oU1Zd-ImpHQ"
  });

  const [ map, setMap ] = useState<mapboxgl.Map>()
  const [ autocomplete, setAutocomplete ] = useState<google.maps.places.AutocompleteService>()
  const [ places, setPlaces ] = useState<google.maps.places.PlacesService>()
  const [ geocoder, setGeocoder ] = useState<google.maps.Geocoder>()

  let markers: mapboxgl.Marker[] = [];
  let polylines: Polyline[] = [];
  let me: mapboxgl.Marker;
  let location: LatLng = { lat: 51.50853, lng: -0.12574 }

  const mapApi: MapApi = {
    currentLocation: () => location,
    addMarker: (position: LatLng, type: MarkerType) => {
      const el = document.createElement('div');
      el.className = `marker ${type === MarkerType.Destination ? 'destination' : 'origin'}`;
      const marker1 = new mapboxgl.Marker(el)
        .setLngLat(position)
        .addTo(map!);
      markers.push(marker1);
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach((marker) => bounds.extend(marker.getLngLat()));
      map?.fitBounds(bounds, { maxZoom: 13 });
    },
    addPolyline: (polyline: Polyline, color: string) => {
      if (!map?.getSource(polyline.id)) {
        map?.addSource(polyline.id, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: polyline.path.map(p => [ p.lng, p.lat ])
            }
          }
        });
      }
      map?.addLayer({
        id: polyline.id,
        type: 'line',
        source: polyline.id,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': color,
          'line-width': 3
        }
      });
      polylines.push(polyline);
    },
    clearMarkers: () => {
      markers.forEach((m) => m.remove());
      markers = [];
    },
    clearPolylines: () => {
      polylines.forEach((p) => map?.removeLayer(p.id));
      polylines = [];
    },
    clear: () => {
      mapApi.clearMarkers();
      mapApi.clearPolylines();
    },
    markProgress: (index: number, progress: number) => {
      let polyline = polylines[index];
      let points = polyline.path;
      let pointIdx = Math.floor((progress / 100) * (points.length - 1));
      let point = points[pointIdx];
      mapApi.setMe(point);
    },
    clearProgress: () => {
      me.remove();
    },
    setPolylineBounds: (index: number) => {
      const polyline = polylines[index];
      const bounds = new mapboxgl.LngLatBounds();
      polyline.path.forEach((point) => bounds.extend(point));
      map?.fitBounds(bounds);
    },
    setMe: (position: LatLng, center?: boolean) => {
      if (me) {
        me.setLngLat(position)
      } else {
        const el = document.createElement('div');
        el.className = 'marker me';
        me = new mapboxgl.Marker(el)
          .setLngLat(position)
          .addTo(map!);
      }
      if (center) {
        map?.setCenter(mapApi.locationWithOffset(position));
      }
    },
    setHeading: (degrees: number) => {
      // if (me && me.getMap()) {
      //   if (heading) {
      //     heading.setPosition(me.getPosition())
      //     heading.setIcon({ ...HeadingIcon, rotation: degrees });
      //   } else {
      //     heading = new google.maps.Marker({
      //       position: new google.maps.LatLng(me.getPosition()!),
      //       icon: { ...HeadingIcon, rotation: degrees },
      //       map,
      //     });
      //   }
      // }
    },
    center: () => {
      map?.setZoom(13);
      map?.setCenter(mapApi.locationWithOffset({
        lat: 51.50853,
        lng: -0.12574
      }));
    },
    locationWithOffset: (location: LatLng): LatLng => {
      const point = map?.project(location);
      const offset = new mapboxgl.Point(
        0,
        400 / Math.pow(2, 1 + map?.getZoom()!)
      );
      const final = map?.unproject(new mapboxgl.Point(
        point!.x,
        point!.y + offset.y
      ));
      return { lat: final!.lat, lng: final!.lng };
    },
    updateBounds: () => {
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach((marker) => bounds.extend(marker.getLngLat()));
      polylines.forEach((polyline) => polyline.path.forEach((point) => bounds.extend(point)));
      map?.fitBounds(bounds, { maxZoom: 13 });
    }
  };

  const onLoad = React.useCallback(function callback(map: any) {
    setAutocomplete(new google.maps.places.AutocompleteService());
    setPlaces(new google.maps.places.PlacesService(document.createElement('div')));
    setGeocoder(new google.maps.Geocoder())
    setMap(map.target);
    window.map = map.target;
    map.target.setPadding({
      top: 52,
      right: 32,
      left: 32,
      bottom: 420
    })
  }, [])

  return isLoaded ? (
    <Map
      onLoad={onLoad}
      mapboxAccessToken="pk.eyJ1IjoibHNlcnJhbGhlaXJvLXNpbHZlcnJhaWx0ZWNoIiwiYSI6ImNsbTY3dHFpNTBjNmQzam82ZnpqbTJ6N3QifQ.mV71JZo1nNv0i3tDv-JnHg"
      initialViewState={{
        latitude: location.lat,
        longitude: location.lng,
        zoom: 13,
      }}
      mapStyle="mapbox://styles/lserralheiro-silverrailtech/clm6gy45j00z101nzh6k390r2"
      attributionControl={false}
    >
      {token && map && places && autocomplete && geocoder && (
        <GoogleApisContext.Provider value={{ autocomplete, places, geocoder, mapApi }}>
          {children}
        </GoogleApisContext.Provider>
      )}
    </Map>
  ) : <></>
}

export default MapBoxContainer;