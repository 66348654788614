import { useLocation, useNavigate, useParams } from "react-router-dom";
import BottomSheet from "../components/BottomSheet";
import { useApiGet, useApiList } from "../api/ApiHooks";
import { Apis } from "../api/Config";
import { Gateway, OrderItem, OrderStatus, PaymentMethod, Ticket, Trip } from "../api/models/Vivi";
import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import StripeCheckout from "../components/checkout/StripeCheckout";
import { useToken } from "../lib/context/TokenContext";
import BraintreeCheckout from "../components/checkout/BraintreeCheckout";

const OrderPage = () => {
  const { id } = useParams<any>();
  const token = useToken();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const returnType = params.get('return') || '';
  const swipe = (params.get('swipe') || '') === 'true';
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(true)

  const {
    data: order,
    loading: loadingOrder,
  } = useApiGet(Apis.Vivi.Order, { token }, {
    id,
    fields: [
      '*',
      'customer.email',
      'customer.address.id',
      'transactions.*',
      'trip.journeyId',
      'orderItems.fare',
      'orderItems.legs',
      'trip.legs.mode',
      'trip.fares.from.name',
      'trip.fares.from.*',
      'trip.fares.from.subLocations.*',
      'trip.fares.to.name',
      'trip.fares.to.*',
      'trip.fares.to.subLocations.*',
      'trip.fares.name',
      'trip.fares.cabinClass',
      'trip.fares.price',
      'trip.fares.legs',
      'trip.fares.roundTrip',
      'trip.fares.metadata',
      'trip.fares.fareRules.*',
    ]
  });
  const {
    data: paymentMethodsPage,
    loading: loadingPaymentMethods,
  } = useApiList(Apis.Vivi.PaymentMethod, {
    token,
    user: { id: token }
  }, { fields: [ 'id', 'gateway', 'descriptor.brand', 'descriptor.display' ] })

  useEffect(() => {
    setLoading(loadingOrder || loadingPaymentMethods);
  }, [ loadingOrder, loadingPaymentMethods ]);

  const trip = order?.trip as Trip;
  const tickets = (order?.orderItems as OrderItem[])?.reduce((b, i) =>
      b.concat(i.tickets as Ticket[]),
    [] as Ticket[]);
  const paymentMethods = paymentMethodsPage?.content as PaymentMethod[];

  const navigateToOrigin = () => {
    if (!returnType || returnType === 'suggestions') {
      navigate(`/suggestions?selected=${trip.id}`)
    } else if (returnType === 'results') {
      navigate(`/results/${trip.journeyId}?selected=${trip.id}`)
    } else if (returnType === 'trips') {
      navigate(`/trips/${trip.id}?selected=${tickets?.[0]?.legs?.[0]}`)
    } else if (returnType === 'settings') {
      navigate('/settings/trips')
    } else {
      console.error("HELP: Don't know where to send the user");
    }
  };

  if (order?.status === OrderStatus.COMPLETE) {
    navigateToOrigin();
  }

  return (<BottomSheet
    detent={"full-height"}
    onClose={navigateToOrigin}
    disableDrag
    disableClose
  >
    {loading
      ? <Stack alignItems={"center"} justifyContent={"center"} minHeight={396}>
        <CircularProgress/>
      </Stack>
      : <Box padding={"1em"} height={"100%"} boxSizing={"border-box"}>
        {order?.status !== OrderStatus.COMPLETE &&
          (order?.gateway === Gateway.STRIPE
              ? <StripeCheckout
                swipe={swipe}
                order={order!}
                paymentMethods={paymentMethods}
                onComplete={() => {
                  setLoading(true);
                  navigateToOrigin();
                }}
              />
              : <BraintreeCheckout
                swipe={swipe}
                order={order!}
                paymentMethods={paymentMethods}
                onComplete={() => {
                  setLoading(true);
                  navigateToOrigin();
                }}
              />
          )
        }
      </Box>
    }
  </BottomSheet>);
};

export default OrderPage;
