import {
  Address,
  Customer,
  Fare,
  Order,
  OrderItem,
  OrderStatus,
  PaymentMethod,
  Transaction,
  TransactionStatus,
  Trip,
  ViviLocation
} from "../../api/models/Vivi";
import React, { useEffect, useMemo, useState } from "react";
import FareSummary from "../FareSummary";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import apiFor from "../../api/Api";
import { Apis } from "../../api/Config";
import countries from "../../lib/countries";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../FormikTextField";
import { useToken } from "../../lib/context/TokenContext";
import { useUser } from "../../lib/context/UserContext";
import {
  ApplePay,
  applePay,
  Client,
  client,
  GooglePayment,
  googlePayment,
  HostedFields,
  hostedFields,
  PayPal,
  paypal,
  threeDSecure
} from "braintree-web";
import { HostedFieldsHostedFieldsFieldData } from "braintree-web/hosted-fields";

const BraintreeInput = React.forwardRef((props: InputBaseComponentProps, ref) => {
  const { component: Component, ...other } = props;
  React.useImperativeHandle(ref, () => ({
    focus: () => {
    },
  }));
  return <Component {...other} />;
});

const BraintreeCheckout = ({
  swipe,
  order: receivedOrder,
  paymentMethods,
  onComplete
}: {
  swipe: boolean
  order: Order
  paymentMethods: PaymentMethod[]
  onComplete: () => void
}) => {
  const token = useToken();
  const user = useUser();

  const [ braintree, setBraintree ] = useState<Client>();
  const [ hostedFieldsInstance, setHostedFieldsInstance ] = useState<HostedFields>();
  const [ applePayInstance, setApplePayInstance ] = useState<ApplePay>();
  const [ googlePayInstance, setGooglePayInstance ] = useState<GooglePayment>();
  const [ paypalInstance, setPaypalInstance ] = useState<PayPal>();
  const [ loading, setLoading ] = useState(swipe);
  const [ order, setOrder ] = useState(receivedOrder);
  const [ paymentMethod, setPaymentMethod ] = useState(paymentMethods.find(pm => user.defaultPaymentMethod === pm.id)?.id || 'wallet');
  const orderApi = apiFor(Apis.Vivi.Order, { token });

  const price = '' + order.total! / 100

  useMemo(() => {
    client.create({
      authorization: ((order.transactions as Transaction[])
        ?.find(t => t.status === TransactionStatus.PENDING)?.metadata as { clientToken: string })
        ?.clientToken
    })
      .then((client) => {
        setBraintree(client);
        if (!!window.ApplePaySession) {
          applePay.create({ client }).then((instance) => {
            setApplePayInstance(instance);
          });
        } else {
          googlePayment.create({
            client,
            googlePayVersion: 2,
            googleMerchantId: 'BCR2DN4T43Z5NYJU'
          }).then((instance) => {
            setGooglePayInstance(instance);
          });
        }
        paypal.create({ client }).then((instance) => {
          setPaypalInstance(instance);
        });
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fares = (order.trip as Trip).fares as Fare[];
  const fare = fares.find(f => f.id === (order?.orderItems as OrderItem[])?.[0]?.fare as string)!;

  useEffect(() => {
    if (swipe) {
      orderApi.update({
        id: order.id!,
        obj: {
          paymentMethod,
        },
        fields: [ 'transactions.*' ]
      }).then((updatedOrder) => {
        orderApi.update({
          id: order.id!,
          obj: {
            status: OrderStatus.COMPLETE
          },
          fields: [ 'transactions.*' ]
        }).then((updatedOrder) => {
          setLoading(false);
          onComplete();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ swipe ]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (order?.customer as Customer)?.email,
      postalCode: ((order?.customer as Customer)?.address as Address)?.postalCode || '',
      country: ((order?.customer as Customer)?.address as Address)?.country || 'GB',
      cardNumber: undefined,
      cardNumberError: 'Card number is required',
      cardExpiryDate: undefined,
      cardExpiryDateError: 'Expiry date is required',
      cardCvv: undefined,
      cardCvvError: 'CVV is required',
      saveCard: false
    },
    onSubmit: async (values) => {
      setLoading(true);
      hostedFieldsInstance
        ?.tokenize({ vault: formik.values.saveCard })
        ?.then((tokenized) => {
          threeDSecure.create({
            client: braintree,
            version: 2
          }).then((threeDS) => {
            threeDS.on('lookup-complete', function (data, next) {
              if (next) next();
            });
            threeDS.verifyCard({
              nonce: tokenized.nonce,
              amount: price,
              bin: tokenized.details.bin,
            }).then((verification) => {
              if (verification.threeDSecureInfo.liabilityShifted) {
                orderApi.update({
                  id: order.id!,
                  obj: {
                    customer: {
                      id: (order?.customer as Customer)?.id || undefined,
                      email: formik.values.email,
                      address: {
                        id: ((order?.customer as Customer)?.address as Address)?.id || undefined,
                        postalCode: formik.values.postalCode,
                        country: formik.values.country
                      }
                    },
                    metadata: {
                      nonce: verification?.nonce,
                      savedCard: formik.values.saveCard ? {
                        brand: tokenized.details.cardType.toLowerCase(),
                        last4: tokenized.details.lastFour,
                        expirationMonth: +tokenized.details.expirationMonth,
                        expirationYear: +tokenized.details.expirationYear
                      } : undefined
                    },
                    status: OrderStatus.COMPLETE
                  },
                  fields: [ 'transactions.*' ]
                }).then((updatedOrder) => {
                  setLoading(false);
                  onComplete();
                });
              } else {
                alert('Payment failed');
              }
            });
          });
        })
        ?.catch((err) => {
          switch (err.code) {
            // case 'HOSTED_FIELDS_FIELDS_EMPTY':
            //   console.error('All fields are empty! Please fill out the form.');
            //   break;
            // case 'HOSTED_FIELDS_FIELDS_INVALID':
            //   // occurs when certain fields do not pass client side validation
            //   console.error('Some fields are invalid:', err.details.invalidFieldKeys);
            //
            //   // you can also programmatically access the field containers for the invalid fields
            //   err.details.invalidFields.forEach(function (fieldContainer) {
            //     fieldContainer.className = 'invalid';
            //   });
            //   break;
            case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
              formik.setFieldValue('cardNumberError', 'This payment method already exists in your vault.', true);
              break;
            case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
              formik.setFieldValue('cardCvvError', 'CVV did not pass verification', true);
              break;
            case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
              console.error('Tokenization failed server side. Is the card valid?');
              break;
            case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
              console.error('Network error occurred when tokenizing.');
              break;
            default:
              console.error('Something bad happened!', err);
          }
        });
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      cardNumber: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardNumberError) {
            return validationContext?.createError({
              message: parent?.cardNumberError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      cardExpiryDate: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardExpiryDateError) {
            return validationContext?.createError({
              message: parent?.cardExpiryDateError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      cardCvv: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardCvvError) {
            return validationContext?.createError({
              message: parent?.cardCvvError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      email: Yup.string().email('Please enter a valid email address').required('Email is required'),
      postalCode: Yup.string()
        .trim()
        .min(2, 'Postal code is too short')
        .required('Please enter your postal code'),
      country: Yup.string().required('Please select a country'),
    }),
  });

  return (
    <Stack id={"special-sauce"} spacing={"1em"} style={{ overflow: 'auto', height: '100%' }}
           justifyContent={"space-between"}>
      <Stack spacing={"1em"}>
        <Stack spacing={"1em"}>
          <FareSummary
            disabled={loading}
            tripId={(order.trip as Trip).id}
            fare={fare}
            fares={fares}
            checked={true}
            onFareChange={(active, fare) => {
              setLoading(true);
              orderApi.update({
                id: order.id!,
                fields: [
                  '*',
                  'transactions.*',
                  'trip.journeyId',
                  'orderItems.fare',
                  'trip.legs.mode',
                  'trip.fares.from.name',
                  'trip.fares.to.name',
                  'trip.fares.name',
                  'trip.fares.price',
                  'trip.fares.legs',
                ],
                obj: { orderItems: [ { fare: fare.id, } ], }
              }).then((o) => {
                setOrder(o);
                setLoading(false);
              })
            }}
            from={fare.from as ViviLocation}
            to={fare.to as ViviLocation}
          />
        </Stack>
        <FormControl>
          <InputLabel id="payment-method">Payment Method</InputLabel>
          <Select
            disabled={loading}
            autoComplete="payment-method"
            labelId="payment-method"
            label="Payment Method"
            name="payment-method"
            value={paymentMethod}
            onChange={(e) => {
              setPaymentMethod(e.target.value);
              if (e.target.value === 'card' && !hostedFieldsInstance) {
                hostedFields.create({
                  client: braintree,
                  styles: {
                    input: {
                      'font-size': '1em',
                      'font-family': 'roboto, verdana, sans-serif',
                      'font-weight': 'lighter',
                      'color': 'black'
                    },
                    // ':focus': {
                    //   'color': 'black'
                    // },
                    // '.valid': {
                    //   'color': 'black'
                    // },
                    // '.invalid': {
                    //   'color': 'black'
                    // }
                  },
                  fields: {
                    number: { selector: '#cardNumber', placeholder: '1234 1234 1234 1234' },
                    expirationDate: { selector: '#cardExpiryDate', placeholder: 'MM / YY' },
                    cvv: { selector: '#cardCvv', placeholder: 'CVV' },
                  },
                }).then((instance) => {
                  setHostedFieldsInstance(instance);

                  const fieldName = (name: string): string => {
                    if (name === 'number') {
                      return 'cardNumber';
                    } else if (name === 'expirationDate') {
                      return 'cardExpiryDate';
                    } else if (name === 'cvv') {
                      return 'cardCvv';
                    }
                    return '';
                  }

                  const validityChange = (emittedBy: string, field: HostedFieldsHostedFieldsFieldData) => {
                    const formikName = fieldName(emittedBy);
                    const formikErrorName = `${fieldName(emittedBy)}Error`;

                    formik.setFieldTouched(formikName, true)
                    if ((field.isFocused && field.isPotentiallyValid) || field.isValid) {
                      formik.setFieldValue(formikErrorName, undefined, true);
                    } else {
                      let message;
                      if (field.isEmpty) {
                        switch (formikName) {
                          case 'cardNumber':
                            message = 'Card number is required';
                            break;
                          case 'cardExpiryDate':
                            message = 'Expiry date is required'
                            break;
                          case 'cardCvv':
                            message = 'CVV is required'
                            break;
                        }
                      } else {
                        switch (formikName) {
                          case 'cardNumber':
                            message = 'Invalid card number';
                            break;
                          case 'cardExpiryDate':
                            message = 'Invalid expiry date'
                            break;
                          case 'cardCvv':
                            message = 'Invalid cvv'
                            break;
                        }
                      }
                      formik.setFieldValue(formikErrorName, message, true);
                    }
                  }

                  instance.on('validityChange', function (event) {
                    validityChange(event.emittedBy, event.fields[event.emittedBy]);
                  });
                  instance.on('blur', function (event) {
                    validityChange(event.emittedBy, event.fields[event.emittedBy]);
                  });
                });
              }
            }}
            MenuProps={{ style: { zIndex: 9999999 } }}
          >
            <MenuItem value={"wallet"}>{!!window.ApplePaySession ? 'Apple' : 'Google'} Pay</MenuItem>
            <MenuItem value={"paypal"}>Paypal</MenuItem>
            {paymentMethods.map((pm) => (
              <MenuItem key={pm.id} value={pm.id}>{pm.descriptor.brand} {pm.descriptor.display}</MenuItem>
            ))}
            <MenuItem value={"card"}>Card</MenuItem>
          </Select>
        </FormControl>
        {paymentMethod === 'card' &&
          <>
            <FormikTextField
              required
              disabled={loading}
              autoComplete="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email address"
              name="email"
              type="email"
            />
            <TextField
              fullWidth
              required
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: BraintreeInput,
                inputProps: {
                  component: "div",
                  id: "cardNumber",
                },
              }}
              error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}
              label="Card number"
              name="cardNumber"
            />
            <Stack direction={"row"} spacing={"1em"}>
              <TextField
                fullWidth
                required
                disabled={loading}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: BraintreeInput,
                  inputProps: {
                    component: "div",
                    id: "cardExpiryDate",
                  },
                }}
                error={formik.touched.cardExpiryDate && Boolean(formik.errors.cardExpiryDate)}
                helperText={formik.touched.cardExpiryDate && formik.errors.cardExpiryDate}
                label="Expiry date"
                name="cardExpiryDate"
              />
              <TextField
                fullWidth
                required
                disabled={loading}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: BraintreeInput,
                  inputProps: {
                    component: "div",
                    id: "cardCvv",
                  },
                }}
                error={formik.touched.cardCvv && Boolean(formik.errors.cardCvv)}
                helperText={formik.touched.cardCvv && formik.errors.cardCvv}
                label="CVV"
                name="cardCvv"
              />
              <div id="cardCvv" style={{ height: '1.4375em' }}/>
            </Stack>
            <FormikTextField
              required
              disabled={loading}
              autoComplete="postal-code"
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              label="Postal code"
              name="postalCode"
            />
            <FormControl>
              <InputLabel id="country">Country</InputLabel>
              <Select
                required
                disabled={loading}
                autoComplete="country"
                error={formik.touched.country && Boolean(formik.errors.country)}
                labelId="country"
                label="Choose a country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                MenuProps={{ style: { zIndex: 9999999 } }}
              >
                <MenuItem value={"GB"}>
                  <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                    <Box>
                      <img
                        loading="lazy"
                        style={{ width: '1.25em' }}
                        src={'https://flagcdn.com/w20/gb.png'}
                        srcSet={'https://flagcdn.com/w40/gb.png 2x'}
                        alt=""
                      />
                    </Box>
                    <Box>
                      United Kingdom
                    </Box>
                  </Stack>
                </MenuItem>
                {countries.filter(c => c.code !== 'GB')
                  .map((c) => <MenuItem key={c.code} value={c.code}>
                    <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                      <Box>
                        <img
                          loading="lazy"
                          style={{ width: '1.25em' }}
                          src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      </Box>
                      <Box>
                        {c.label}
                      </Box>
                    </Stack>
                  </MenuItem>)
                }
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required component="fieldset" margin="dense">
              <FormGroup row aria-label="position">
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={formik.values.saveCard}
                      color="secondary"
                      name="saveCard"
                      onChange={formik.handleChange}
                    />
                  }
                  label="Save card"
                  value="No"
                />
              </FormGroup>
            </FormControl>
          </>
        }
      </Stack>
      <Button
        disabled={loading}
        variant={"contained"}
        onClick={() => {
          switch (paymentMethod) {
            case 'paypal':
              paypalInstance?.tokenize({
                flow: 'vault',
                currency: 'GBP',
                amount: price
              }).then((tokenized) => {
                orderApi.update({
                  id: order.id!,
                  obj: {
                    orderItems: [ { fare: fare.id, } ],
                    customer: {
                      address: {
                        id: ((order?.customer as Customer)?.address as Address)?.id || undefined,
                        city: tokenized.details.billingAddress.city || '',
                        country: tokenized.details.billingAddress.countryCode || '',
                        line1: tokenized.details.billingAddress.line1 || '',
                        postalCode: tokenized.details.billingAddress.postalCode || 'N/A',
                      },
                      email: tokenized.details.email || '',
                      name: `${tokenized.details.firstName} ${tokenized.details.lastName}`,
                      id: (order?.customer as Customer)?.id || undefined,
                    },
                    metadata: { nonce: tokenized?.nonce }
                  },
                  fields: [ 'transactions.*' ]
                }).then((updatedOrder) => {
                  orderApi.update({
                    id: order.id!,
                    obj: {
                      status: OrderStatus.COMPLETE
                    },
                    fields: [ 'transactions.*' ]
                  }).then((updatedOrder) => {
                    setLoading(false);
                    onComplete();
                  });
                });
              });
              break;
            case 'wallet':
              if (!!window.ApplePaySession) {
                const applePayPaymentRequest = applePayInstance!.createPaymentRequest({
                  currencyCode: 'GBP',
                  total: { label: order.reference!, amount: price },
                  requiredBillingContactFields: [ 'name', 'email', 'postalAddress' ],
                });
                const session = new window.ApplePaySession(3, applePayPaymentRequest);
                session.onvalidatemerchant = (event: any) => {
                  applePayInstance!.performValidation({
                    validationURL: event.validationURL,
                    displayName: 'Vivi'
                  }).then(function (merchantSession) {
                    session.completeMerchantValidation(merchantSession);
                  }).catch(function (validationErr) {
                    // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                    console.error('Error validating merchant:', validationErr);
                    session.abort();
                  });
                };
                session.onpaymentauthorized = (event: any) => {
                  setLoading(true);
                  applePayInstance!.tokenize({
                    token: event.payment.token
                  }).then((tokenized) => {
                    session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                    orderApi.update({
                      id: order.id!,
                      obj: {
                        orderItems: [ { fare: fare.id, } ],
                        customer: {
                          address: {
                            id: ((order?.customer as Customer)?.address as Address)?.id || undefined,
                            city: event.payment.billingContact.city || '',
                            country: event.payment.billingContact.countryCode || '',
                            line1: event.payment.billingContact.addressLines[0] || '',
                            postalCode: event.payment.billingContact.postalCode || 'N/A',
                          },
                          email: event.payment.billingContact.emailAddress || '',
                          name: event.payment.billingContact.name || '',
                          id: (order?.customer as Customer)?.id || undefined,
                        },
                        metadata: { nonce: tokenized?.nonce }
                      },
                      fields: [ 'transactions.*' ]
                    }).then((updatedOrder) => {
                      orderApi.update({
                        id: order.id!,
                        obj: {
                          status: OrderStatus.COMPLETE
                        },
                        fields: [ 'transactions.*' ]
                      }).then((updatedOrder) => {
                        setLoading(false);
                        onComplete();
                      });
                    });
                  }).catch(function (tokenizeErr) {
                    console.error('Error tokenizing Apple Pay:', tokenizeErr);
                    session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                  });
                };
                session.begin();
              } else {
                const paymentsClient = new google.payments.api.PaymentsClient({
                  environment: 'TEST' // Or 'PRODUCTION'
                })
                const paymentDataRequest = googlePayInstance?.createPaymentDataRequest({
                  transactionInfo: {
                    currencyCode: 'GBP',
                    totalPriceStatus: 'FINAL',
                    totalPrice: price
                  }
                }) as unknown as google.payments.api.PaymentDataRequest;
                paymentsClient.isReadyToPay({
                  apiVersion: 2,
                  apiVersionMinor: 0,
                  allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
                  existingPaymentMethodRequired: true
                }).then(() => {
                  paymentDataRequest.emailRequired = true;
                  paymentDataRequest.allowedPaymentMethods[0].parameters.billingAddressParameters = { format: 'FULL' };
                  paymentDataRequest.allowedPaymentMethods[0].parameters.billingAddressRequired = true;
                  paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
                    googlePayInstance?.parseResponse(paymentData).then((tokenized) => {
                      orderApi.update({
                        id: order.id!,
                        obj: {
                          orderItems: [ { fare: fare.id, } ],
                          customer: {
                            address: {
                              id: ((order?.customer as Customer)?.address as Address)?.id || undefined,
                              city: paymentData.paymentMethodData.info?.billingAddress?.locality || '',
                              country: paymentData.paymentMethodData.info?.billingAddress?.countryCode || '',
                              line1: paymentData.paymentMethodData.info?.billingAddress?.address1 || '',
                              line2: paymentData.paymentMethodData.info?.billingAddress?.address2 || undefined,
                              postalCode: paymentData.paymentMethodData.info?.billingAddress?.postalCode || 'N/A',
                            },
                            email: paymentData.email || '',
                            name: paymentData.paymentMethodData.info?.billingAddress?.name || '',
                            id: (order?.customer as Customer)?.id || undefined,
                          },
                          metadata: { nonce: tokenized?.nonce }
                        },
                        fields: [ 'transactions.*' ]
                      }).then((updatedOrder) => {
                        orderApi.update({
                          id: order.id!,
                          obj: {
                            status: OrderStatus.COMPLETE
                          },
                          fields: [ 'transactions.*' ]
                        }).then((updatedOrder) => {
                          setLoading(false);
                          onComplete();
                        });
                      });
                    });
                  });
                });
              }
              break;
            case 'card':
              formik.submitForm();
              break;
            default:
              orderApi.update({
                id: order.id!,
                obj: {
                  paymentMethod,
                },
                fields: [ 'transactions.*' ]
              }).then((updatedOrder) => {
                orderApi.update({
                  id: order.id!,
                  obj: {
                    status: OrderStatus.COMPLETE
                  },
                  fields: [ 'transactions.*' ]
                }).then((updatedOrder) => {
                  setLoading(false);
                  onComplete();
                });
              })
          }
        }}>
        {loading ? (<CircularProgress size={"1.75em"}/>) : `Pay ${order.total! / 100}`}
      </Button>
    </Stack>
  );
};

export default BraintreeCheckout;
