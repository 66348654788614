import { useToken } from "../../lib/context/TokenContext";
import { useApiList } from "../../api/ApiHooks";
import { Apis } from "../../api/Config";
import apiFor from "../../api/Api";
import { Box, Button, CircularProgress, IconButton, Stack, styled } from "@mui/material";
import { Direction, Favourite, Route, Schedule } from "../../api/models/Vivi";
import React, { useState } from "react";
import { deepPurple } from "@mui/material/colors";
import { ArrowRightAlt, Delete } from "@mui/icons-material";
import ScheduleForm from "./ScheduleForm";
import { toTimeISOString } from "../../lib/DateTime";
import { Operation } from "../../api/Search";

const Bubble = styled(Stack)`
  background-color: ${deepPurple[50]};
  padding: 1em;
  border-radius: 0.5em;
  cursor: pointer;
`;
const WeekDay = styled(Stack)`
  background-color: white;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  text-align: center;

  span {
    margin-top: 0.45em;
  }

  &.active {
    border-radius: 0.75em;
    color: white;
    background-color: ${deepPurple[400]};
  }
`;

const enumToLabel = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

const Schedules = () => {
  const token = useToken();
  const [ selected, setSelected ] = useState<Route>();

  const routeApi = apiFor(Apis.Vivi.Route, { token });
  const { data, loading, refetch } = useApiList(Apis.Vivi.Route, { token }, {
    page: 1,
    pageSize: 100,
    search: [ { field: "deleted", op: Operation.Equals, value: false } ],
    fields: [
      'from.name',
      'from.type',
      'from.value',
      'from.address',
      'to.name',
      'to.type',
      'to.value',
      'to.address',
      'schedules.start',
      'schedules.end',
      'schedules.frequency',
      'schedules.cadence',
      'schedules.direction',
      'schedules.time',
      'schedules.mode',
      'schedules.values'
    ]
  });

  if (loading) {
    return <Stack alignItems={"center"} justifyContent={"center"}>
      <CircularProgress/>
    </Stack>;
  }

  const now = new Date();

  return selected
    ? <ScheduleForm onCancel={() => setSelected(undefined)} onSave={(route) => {
      setSelected(undefined);
      (route.id ? routeApi.update({ id: route.id, obj: route }) : routeApi.create({ obj: route }))
        .then(refetch)
    }} selected={selected}/>
    : <Stack height={"100%"} spacing={"1em"}>
      <span style={{ fontSize: "1.5em", fontWeight: "600" }}>Schedules</span>
      <span style={{ fontWeight: "100" }}>Vivi will check for delays, recommend a route and make it easy to buy tickets for your schedules.</span>
      {data?.content.map((route) => {
        const outbound = (route.schedules as Schedule[])?.find((s) => s.direction === Direction.OUTBOUND);
        const inbound = (route.schedules as Schedule[])?.find((s) => s.direction === Direction.INBOUND);
        return (
          <Bubble key={route.id} spacing={"1em"} onClick={() => setSelected(route)}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack style={{ fontSize: "1.25em", fontWeight: "600" }}>
                <Box>{(route.from as Favourite).name}</Box>
                <Stack direction={"row"}>
                  <ArrowRightAlt/>
                  <Box>
                    {(route.to as Favourite).name}
                  </Box>
                </Stack>
              </Stack>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                routeApi.remove({ id: route.id! }).then(refetch);
              }}><Delete/></IconButton>
            </Stack>

            <Stack direction={"row"} spacing={"2em"}>
              <Box><span
                style={{ fontWeight: "100" }}>Out</span> {enumToLabel(outbound?.mode as string)} {toTimeISOString(
                new Date(now.toISOString().substring(0, 10) + 'T' + outbound!.time!),
              )}</Box>
              <Box><span
                style={{ fontWeight: "100" }}>Return</span> {enumToLabel(inbound?.mode as string)} {toTimeISOString(
                new Date(now.toISOString().substring(0, 10) + 'T' + inbound!.time!)
              )}</Box>
            </Stack>
            <Stack direction={"row"} spacing={"0.5em"}>
              <Box marginRight={"1em"} style={{ fontWeight: "100", marginTop: "0.45em" }}>Days</Box>
              <WeekDay className={outbound?.values?.find((v) => v === 1) ? "active" : ""}><span>M</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 2) ? "active" : ""}><span>T</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 3) ? "active" : ""}><span>W</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 4) ? "active" : ""}><span>T</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 5) ? "active" : ""}><span>F</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 6) ? "active" : ""}><span>S</span></WeekDay>
              <WeekDay className={outbound?.values?.find((v) => v === 7) ? "active" : ""}><span>S</span></WeekDay>
            </Stack>
          </Bubble>
        );
      })}
      <Button variant={"contained"} onClick={() => setSelected({})}>Add Schedule</Button>
    </Stack>;
}
export default Schedules;