import React, { useEffect, useState } from "react";

export type PickerOption = { label: string };

const PickerColumn = (
  {
    options,
    name,
    initialValue,
    itemHeight,
    columnHeight,
    onChange,
    onClick,
    wheel,
  }:
    {
      options: PickerOption[],
      name: string,
      initialValue: PickerOption,
      itemHeight: number,
      columnHeight: number,
      onChange: (name: string, value: PickerOption) => void,
      onClick: (name: string, value: PickerOption) => void,
      wheel: 'off' | 'natural' | 'normal'
    }
) => {
  const [ value, setValue ] = useState<PickerOption>(initialValue)
  const computeTranslate = () => {
    let selectedIndex = options.map((o) => o.label).indexOf(value.label);
    if (selectedIndex < 0) {
      // throw new ReferenceError();
      console.warn('Warning: "' + name + '" doesn\'t contain an option of "' + value + '".');
      onValueSelected(options[0]);
      selectedIndex = 0;
    }
    return {
      scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate: columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2
    };
  };

  const [ state, setState ] = useState({
    isMoving: false,
    startTouchY: 0,
    startScrollerTranslate: 0,
    ...(computeTranslate())
  })

  useEffect(() => {
    if (state.isMoving) {
      return;
    }
    setState({ ...state, ...(computeTranslate()) });
    // eslint-disable-next-line
  }, [ value ])

  const onValueSelected = (newValue: PickerOption) => {
    setValue(newValue);
    onChange(name, newValue);
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    const {
      scrollerTranslate
    } = state;

    let delta = event.deltaY * 0.1;
    if (Math.abs(delta) < itemHeight) {
      delta = itemHeight * Math.sign(delta);
    }

    switch (wheel) {
      case 'natural':
        // ignore and continue
        break;
      case 'normal':
        delta = delta * -1;
        break;
      default:
        return;
    }

    onScrollerTranslateSettled(scrollerTranslate + delta);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const startTouchY = event.targetTouches[0].pageY;
    setState(({ scrollerTranslate }) => ({
      ...state,
      startTouchY,
      startScrollerTranslate: scrollerTranslate
    }));
  };

  const safePreventDefault = (event: React.TouchEvent<HTMLDivElement>) => {
    const passiveEvents = [ 'touchstart', 'touchmove' ];
    if (!passiveEvents.includes(event.type)) {
      event.preventDefault();
    }
  }

  const onScrollerTranslateSettled = (scrollerTranslate: number) => {
    const {
      minTranslate,
      maxTranslate,
    } = state;

    let activeIndex = 0;
    if (scrollerTranslate >= maxTranslate) {
      activeIndex = 0;
    } else if (scrollerTranslate <= minTranslate) {
      activeIndex = options.length - 1;
    } else {
      activeIndex = -Math.round((scrollerTranslate - maxTranslate) / itemHeight);
    }

    onValueSelected(options[activeIndex]);
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    safePreventDefault(event);
    if (!state.isMoving) {
      return setState({
        ...state,
        isMoving: true
      });
    }

    let nextScrollerTranslate = state.startScrollerTranslate + event.targetTouches[0].pageY - state.startTouchY;
    if (nextScrollerTranslate < state.minTranslate) {
      nextScrollerTranslate = state.minTranslate - Math.pow(state.minTranslate - nextScrollerTranslate, 0.8);
    } else if (nextScrollerTranslate > state.maxTranslate) {
      nextScrollerTranslate = state.maxTranslate + Math.pow(nextScrollerTranslate - state.maxTranslate, 0.8);
    }

    setState({
      ...state,
      scrollerTranslate: nextScrollerTranslate
    });
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!state.isMoving) {
      return;
    }
    setState({
      ...state,
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0
    });
    setTimeout(() => {
      onScrollerTranslateSettled(state.scrollerTranslate);
    }, 0);
  };

  const handleTouchCancel = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!state.isMoving) {
      return;
    }
    setState({
      ...state,
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
  };

  const handleItemClick = (option: PickerOption) => {
    if (option !== value) {
      onValueSelected(option);
    } else {
      onClick(name, value);
    }
  };

  const renderItems = () => {
    return options.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px'
      };
      const className = `picker-item${option.label === value.label ? ' picker-item-selected' : ''}`;
      return (
        <div
          key={index}
          className={className}
          style={style}
          onClick={() => handleItemClick(option)}>{option.label}</div>
      );
    });
  };

  const translateString = `translate3d(0, ${state.scrollerTranslate}px, 0)`;
  const style = {
    MsTransform: translateString,
    MozTransform: translateString,
    OTransform: translateString,
    WebkitTransform: translateString,
    transform: translateString,
  };
  if (state.isMoving) {
    (style as any).transitionDuration = '0ms';
  }
  return (
    <div
      className="picker-column"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchCancel}
      onTouchMove={handleTouchMove}
      onWheel={handleWheel}
    >
      <div
        className="picker-scroller"
        style={style}
      >
        {renderItems()}
      </div>
    </div>
  );
}

export default PickerColumn;