import { DateTime } from "./Types";
import React, { useEffect, useState } from "react";
import { Stack, styled, tabClasses } from "@mui/material";
import BottomSheet from "./BottomSheet";
import Picker from "../lib/picker/Picker";
import { leftPad } from "../lib/DateTime";
import { Tab, Tabs, TabsList } from "@mui/base";
import { grey } from "@mui/material/colors";
import { TravelMode } from "../api/models/Vivi";
import { PickerOption } from "../lib/picker/PickerColumn";
import Button from "@mui/material/Button";

const StyledTab = styled(Tab)`
  color: #eee;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  padding: 0.5em;
  margin: 2px;
  border: none;
  border-radius: calc(1em - 2px);
  display: flex;
  justify-content: center;
  flex-grow: 1;

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${grey[600]};
  }
`;

const StyledTabsList = styled(TabsList)(({ theme }) => `
  background-color: ${grey[500]};
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `);

type DateTimePickerOption = {
  value: Date | number
} & PickerOption;

const TimePicker = (
  {
    open,
    setOpen,
    initialTime,
    onSelection,
  }: {
    open: boolean
    setOpen: (open: boolean) => void
    initialTime: DateTime,
    onSelection: (location: DateTime) => void
  }
) => {
  const [ time, setTime ] = useState(initialTime)

  useEffect(() => {
    if (!open) {
      setTime(initialTime);
    }
  }, [initialTime, open]);

  const options = {
    hour: Array.from(Array(24).keys()).map((idx) => ({
      label: leftPad(idx, 2),
      value: idx,
    })),
    minute: Array.from(Array(60).keys()).map((idx) => ({
      label: leftPad(idx, 2),
      value: idx,
    }))
  }

  const values = {
    hour: options.hour[time.value.getHours()],
    minute: options.minute[time.value.getMinutes()],
  }

  return (
    <BottomSheet
      backdrop
      disableClose
      disableDrag
      detent={"full-height"}
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack spacing={"1em"} padding={"1em"}>
        <Tabs
          value={time.mode}
          onChange={(_, value) => setTime({ ...time, mode: value === 'DEPART' ? TravelMode.DEPART : TravelMode.ARRIVE })}
          selectionFollowsFocus
        >
          <StyledTabsList>
            <StyledTab value="DEPART">Depart</StyledTab>
            <StyledTab value="ARRIVE">Arrive</StyledTab>
          </StyledTabsList>
        </Tabs>
        <Picker
          onChange={(name, value: PickerOption) => {
            const date = new Date(time.value);
            const datePickerOption = value as DateTimePickerOption;
            switch (name) {
              case 'hour':
                date.setHours(datePickerOption.value as number);
                break;
              case 'minute':
                date.setMinutes(datePickerOption.value as number);
                break;
            }
            setTime({ ...time, value: date })
          }}
          optionGroups={options}
          valueGroups={values}
          wheel={"natural"}
        />
        <Button variant={"contained"} onClick={() => onSelection({ ...time })}>Continue</Button>
      </Stack>
    </BottomSheet>
  );
};
export default TimePicker;