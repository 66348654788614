import { initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import apiFor from "../api/Api";
import { useToken } from "../lib/context/TokenContext";
import { Apis } from "../api/Config";
import { DeviceType } from "../api/models/Vivi";
import { useSnackbar } from "notistack";
import { browserName, fullBrowserVersion, osName, osVersion, } from 'react-device-detect';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCccVojgOi3rICud1uJQt4oseKgSp0rEy4",
  authDomain: "vivi-dd880.firebaseapp.com",
  projectId: "vivi-dd880",
  storageBucket: "vivi-dd880.appspot.com",
  messagingSenderId: "679984288610",
  appId: "1:679984288610:web:01769181997600e6be923b",
  measurementId: "G-8YX86X1QFC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

async function requestPermission(cb: (token: string) => void) {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: 'BLyU3hgLi4mP0l3e684kJ1mo6s9PMGqvM2_3sm69jM-4sUpIIisCHDPzmankcI_fOyAX3j3uU-5wK2SBsjOzjKM',
    });
    cb(token);
  } else if (permission === "denied") {
    console.log("You denied for the notification");
  }
}

const NotificationHandler = () => {
  const token = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const deviceApi = apiFor(Apis.Vivi.Device, { token });

  useEffect(() => {
    requestPermission((fcmToken) => {
      deviceApi.create({
        obj: {
          description: `${browserName} ${fullBrowserVersion} - ${osName} ${osVersion}`,
          user: token,
          token: fcmToken,
          type: DeviceType.GCM
        }
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessage(messaging, (payload) => {
    console.log("incoming msg", payload);
    if (payload.notification) {
      enqueueSnackbar({
        variant: "warning",
        message: (<>
          <div>
            <div>{payload.notification?.title}</div>
            <div>{payload.notification?.body}</div>
          </div>
        </>)
      })
    }
  });

  return <></>
};

export default NotificationHandler;