import { Box, Button, Dialog, IconButton, Stack, styled, TextField } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React, { useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Favourite } from "../api/models/Vivi";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";

const Bubble = styled(Stack)`
  background-color: ${grey[300]};
  padding: 0.5em 1em;
  border-radius: 0.5em;

  &.active {
    background-color: ${blue[100]};
  }
`;

const FavouriteSummary = (
  {
    favourite,
    token,
    onChange
  }: {
    favourite: Favourite
    token: string
    onChange: () => void
  }
) => {
  const [ edit, setEdit ] = useState(false);
  const [ name, setName ] = useState(favourite.name);
  const favouriteApi = apiFor(Apis.Vivi.Favourite, { token });

  const onDelete = () => {
    favouriteApi.remove({
      id: favourite.id!
    }).then(onChange);
  }

  return (
    <Bubble spacing={"0.75em"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"} spacing={"1em"}>
            <Box>{favourite.name}</Box>
          </Stack>
          <Box sx={{ color: grey[500], fontSize: "0.9em" }}>{favourite.address}</Box>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <IconButton onClick={(e) => {
            e.stopPropagation();
            setEdit(true)
          }}><Edit/></IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}><Delete/></IconButton>
        </Stack>
      </Stack>
      <Dialog fullWidth open={edit} onClose={() => setEdit(false)} sx={{ zIndex: 99999999 }}>
        <Stack padding={"1em"} spacing={"1em"}>
          <Box>{favourite?.address}</Box>
          <TextField
            size={"small"}
            InputLabelProps={{ shrink: true }}
            label="Name"
            placeholder="Name"
            defaultValue={favourite?.name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button variant={"contained"} onClick={() => {
            favouriteApi.update({
              id: favourite.id!,
              obj: {
                name,
              }
            }).then(onChange);
          }}>Save Favourite</Button>
        </Stack>
      </Dialog>
    </Bubble>
  );
}

export default FavouriteSummary;
