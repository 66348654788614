import { Button, Stack } from "@mui/material";
import { useApiList } from "../api/ApiHooks";
import { Apis } from "../api/Config";
import { Favourite } from "../api/models/Vivi";
import { useMemo } from "react";
import { useEventBus } from "../lib/EventBus";
import { useToken } from "../lib/context/TokenContext";

const Menu = (
  {
    onClick,
  }: {
    onClick: (favourite: Favourite) => void
  }
) => {
  const token = useToken();
  const eventBus = useEventBus();
  const { data: favouritesPage, loading, refetch } = useApiList(Apis.Vivi.Favourite, { token }, {
    pageSize: 3,
  });

  useMemo(() => {
    eventBus.on('favourites-modified', () => {
      refetch();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus, refetch ]);

  return (
    <Stack direction={"row"} spacing={"0.5em"} padding={"0.5em"}>
      {!loading && favouritesPage?.content?.map((favourite, idx) =>
        <Button
          sx={{ borderRadius: "1em" }}
          color={"inherit"}
          variant={"contained"}
          key={favourite.id}
          size={"small"}
          onClick={() => onClick(favourite)}
        >
          {favourite.name}
        </Button>
      )}
    </Stack>
  );
};

export default Menu;
