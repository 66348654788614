import { useApiList } from "../../api/ApiHooks";
import { Apis } from "../../api/Config";
import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import TicketSummary from "../TicketSummary";
import { useToken } from "../../lib/context/TokenContext";
import { SortDirection } from "../../api/Search";

const Tickets = () => {
  const token = useToken();
  const { data: ticketsPage, loading } = useApiList(Apis.Vivi.GlobalTicket, { token }, {
    fields: [
      'reference',
      'mimeType',
      'binary',
      'orderItem.fare.name',
      'orderItem.fare.from.name',
      'orderItem.fare.to.name',
      'orderItem.fare.legs.scheduledDepart',
      'orderItem.fare.legs.scheduledArrive',
    ],
    sort: [ { field: 'createdAt', direction: SortDirection.Desc } ],
  });

  return loading
    ? (
      <Stack alignItems={"center"} justifyContent={"center"}>
        <CircularProgress/>
      </Stack>
    )
    : <Stack spacing={"1em"} overflow={"auto"} maxHeight={"86vh"}>
      {
        ticketsPage?.content?.map((ticket) =>
          // eslint-disable-next-line react/jsx-no-undef
          <TicketSummary
            key={ticket.id!}
            ticket={ticket}
          />
        )}
    </Stack>;
};

export default Tickets;
