import React, { ReactNode, useState } from "react";
import { Box, Collapse, Link, Stack } from "@mui/material";
import { CallingPoint, Leg, TransportType, Trip, ViviLocation } from "../api/models/Vivi";
import { grey } from "@mui/material/colors";
import {
  Circle,
  DirectionsBus,
  DirectionsTransit,
  DirectionsWalk, ExpandLess, ExpandMore,
  Subway,
  SwapHoriz, Tram,
  TripOrigin
} from "@mui/icons-material";
import { convertTimeZone, toISOString, toTimeString } from "../lib/DateTime";

const getModeIcon = (leg: Leg): ReactNode => {
  switch (leg.mode) {
    case TransportType.WALK:
      return <DirectionsWalk/>
    case TransportType.BUS:
    case TransportType.REPLACEMENT_BUS:
    case TransportType.COACH:
      return <DirectionsBus/>;
    case TransportType.UNDERGROUND:
      return <Subway/>
    case TransportType.TRAM:
      return <Tram/>
    case TransportType.RAIL:
      return <DirectionsTransit/>;
    default:
      return '???';
  }
};

const getModeTitle = (leg: Leg): string => {
  switch (leg.mode) {
    case TransportType.WALK:
      return 'Walking'
    case TransportType.BUS:
    case TransportType.REPLACEMENT_BUS:
    case TransportType.COACH:
      return 'Bus';
    case TransportType.UNDERGROUND:
      return 'Underground'
    case TransportType.TRAM:
      return 'Tram'
    case TransportType.RAIL:
      return 'Rail';
    default:
      return '???';
  }
};

const spacer = () => {
  return <Stack padding={"0 1em"} alignItems={"flex-start"}>
    <Box sx={{ paddingLeft: "0.75em", textAlign: "center" }}>|</Box>
    <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
      <Box sx={{ backgroundColor: grey[100], padding: "0.25em", borderRadius: "0.25em" }}>
        <SwapHoriz/>
      </Box>
      <span>Change transport modes</span>
    </Stack>
    <Box sx={{ paddingLeft: "0.75em", textAlign: "center" }}>|</Box>
  </Stack>

}

const stationCoordinates = (code: string) => {
  switch (code) {
    case 'urn:x_srt:stn:UKRail:ALTON':
      return [ 51.151272, -0.972452 ];
    case 'urn:x_srt:stn:UKRail:WATRLMN':
      return [ 51.5032498, -0.1171639 ];
    default:
      throw new Error(`Code '${code}' unknown`);
  }
}

const TripDetails = (
  {
    trip
  }: {
    trip: Trip
  }
) => {
  const [ open, setOpen ] = useState<{ [key: string]: boolean }>({})
  const getContent = (leg: Leg, first: boolean, last: boolean): ReactNode => {
    let el: ReactNode;
    switch (leg.mode) {
      case TransportType.WALK:
        const from = (leg.from as CallingPoint).location as ViviLocation;
        const to = (leg.to as CallingPoint).location as ViviLocation;
        const fromParts = from.value?.startsWith('urn:x_srt:stn:UKRail') ? stationCoordinates(from.value) : from.value?.split(",");
        const toParts = to.value?.startsWith('urn:x_srt:stn:UKRail') ? stationCoordinates(to.value) : to.value?.split(",");
        const uberFrom = {
          addressLine1: from.name,
          latitude: +(fromParts?.[0] || ''),
          logitude: +(fromParts?.[1] || '')
        };
        const uberTo = {
          addressLine1: to.name,
          latitude: +(toParts?.[0] || ''),
          logitude: +(toParts?.[1] || '')
        };

        el = <Stack direction={"row"} spacing={"1em"} alignItems={"center"} width={"100%"}>
          <Box sx={{ backgroundColor: grey[100], padding: "0.25em", borderRadius: "0.25em" }}>
            {getModeIcon(leg)}
          </Box>
          <Stack direction={"row"} justifyContent={"space-between"} flex={1}>
            <span>walk for {leg.duration} minutes</span>
            {(first || last) && <Link
              // target={"_blank"}
              href={
                "https://m.uber.com/reserve" +
                `?pickup=${encodeURIComponent(JSON.stringify(uberFrom))}` +
                `&drop%5B0%5D=${encodeURIComponent(JSON.stringify(uberTo))}` +
                (first ? `&dropoff_formatted_time=${encodeURIComponent(toISOString(convertTimeZone({
                  date: leg.arrive ? new Date(leg.arrive!) : new Date(leg.scheduledArrive!),
                  from: "UTC",
                  to: "Europe/London"
                })))}` : '') +
                (last ? `&pickup_formatted_time=${encodeURIComponent(toISOString(convertTimeZone({
                  date: leg.depart ? new Date(leg.depart!) : new Date(leg.scheduledDepart!),
                  from: "UTC",
                  to: "Europe/London"
                })))}` : '') +
                `&utm_campaign=bd-silverrail`
              }
            >
              Uber
            </Link>}
          </Stack>
        </Stack>
        break;
      case TransportType.BUS:
      case TransportType.REPLACEMENT_BUS:
      case TransportType.COACH:
      case TransportType.UNDERGROUND:
      case TransportType.TRAM:
      case TransportType.RAIL:
        const stops = (leg.stops as CallingPoint[]).concat([]);
        stops.splice(0, 1);
        stops.splice(stops.length - 1, 1);
        el = <>
          <Box sx={{ backgroundColor: grey[100], padding: "0.25em", borderRadius: "0.25em" }}>
            {getModeIcon(leg)}
          </Box>
          <Stack spacing={"1em"} style={{ width: "100%" }} flexGrow={1}>
            <Box>take {leg.mode.toLowerCase().replace(/_/g, ' ')} for {leg.duration} minutes</Box>
            <Stack direction={"row"} flexGrow={1} onClick={() =>
              setOpen({ ...open, [leg.id!]: !open[leg.id!] })
            }>
              <Box>{stops.length} stops</Box>
              {open[leg.id!] ? <ExpandLess/> : <ExpandMore/>}
            </Stack>
            <Collapse in={open[leg.id!]} timeout="auto" unmountOnExit>
              <Stack spacing={"1em"}>
                {(stops as CallingPoint[])?.map((s) => (
                  <Stack direction={"row"} spacing={"1em"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Box>{(s.location as ViviLocation).name}</Box>
                    <Box>
                      {s.scheduledArrive
                        ? toTimeString(convertTimeZone({
                          date: leg.arrive ? new Date(s.arrive!) : new Date(s.scheduledArrive!),
                          from: "UTC",
                          to: "Europe/London"
                        }))
                        : ''
                      }
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Collapse>
          </Stack>
        </>
        break;
      default:
        el = '???';
    }
    return <Stack direction={"row"} spacing={"1em"} alignItems={"center"} width={"100%"}>
      {el}
    </Stack>
  };

  const renderLeg = (leg: Leg, first: boolean, last: boolean): ReactNode => {
    const el =
      <Box sx={{ backgroundColor: grey[300], padding: "1em" }}>
        <Stack alignItems={"flex-start"}>
          <Box sx={{ color: grey[700], marginBottom: "0.5em" }}>{getModeTitle(leg)}</Box>
          <Stack direction={"row"} padding={"0 0.25em"} spacing={"1em"} alignItems={"center"} sx={{ width: "100%" }}>
            <Circle/>
            <Stack direction={"row"} spacing={"1em"} justifyContent={"space-between"} sx={{ width: "100%" }}>
              <Box>{((leg.from! as CallingPoint).location as ViviLocation).name}</Box>
              <Box>{leg.scheduledDepart ? toTimeString(convertTimeZone({
                date: leg.depart ? new Date(leg.depart!) : new Date(leg.scheduledDepart!),
                from: "UTC",
                to: "Europe/London"
              })) : ''}</Box>
            </Stack>
          </Stack>
          <Box sx={{ paddingLeft: "0.75em", textAlign: "center" }}>|</Box>
          {getContent(leg, first, last)}
          <Box sx={{ paddingLeft: "0.75em", textAlign: "center" }}>|</Box>
          <Stack direction={"row"} padding={"0 0.25em"} spacing={"1em"} alignItems={"center"} sx={{ width: "100%" }}>
            <TripOrigin/>
            <Stack direction={"row"} spacing={"1em"} justifyContent={"space-between"} sx={{ width: "100%" }}>
              <Box>{((leg.to! as CallingPoint) as ViviLocation).name}</Box>
              <Box>{leg.scheduledArrive ? toTimeString(convertTimeZone({
                date: leg.arrive ? new Date(leg.arrive!) : new Date(leg.scheduledArrive!),
                from: "UTC",
                to: "Europe/London"
              })) : ''}</Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>

    return <Stack key={leg.id!} spacing={"0.5em"}>
      {last ? el : <>{el}{spacer()}</>}
    </Stack>;
  };

  return (
    <Stack spacing={"0.25em"} padding={"1em 0"} overflow={"auto"}>
      {(trip.legs as Leg[])?.map((l, idx) => renderLeg(l, idx === 0, idx === trip.legs!.length - 1))}
    </Stack>
  );
};

export default TripDetails;