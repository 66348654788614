import { Box, Stack, styled } from "@mui/material";
import { Fare, Leg, OrderItem, Ticket, ViviLocation } from "../api/models/Vivi";
import { blue, grey } from "@mui/material/colors";
import React from "react";
import { ArrowForward } from "@mui/icons-material";
import TicketButton from "./TicketButton";
import { convertTimeZone, toDateString, toTimeString } from "../lib/DateTime";

const TicketBubble = styled(Stack)`
  background-color: ${grey[300]};
  padding: 1em;
  border-radius: 0.5em;

  &.active {
    background-color: ${blue[100]};
  }
`;

const TicketSummary = (
  {
    ticket,
  }: {
    ticket: Ticket
  }
) => {
  const legs = ((ticket.orderItem as OrderItem).fare as Fare).legs;
  let depart = convertTimeZone({
    date: new Date((legs as Leg[])[0].scheduledDepart!),
    from: "UTC",
    to: "Europe/London"
  });
  let arrive = convertTimeZone({
    date: new Date((legs as Leg[])[legs!.length - 1].scheduledArrive!),
    from: "UTC",
    to: "Europe/London"
  });
  const fare = (ticket.orderItem as OrderItem).fare as Fare;
  return (
    <TicketBubble
      spacing={"1em"}
    >
      <Stack spacing={"1em"}>
        <Stack spacing={"0.25em"}>
          <Stack direction={"row"} spacing={"0.25em"} alignItems={"center"}>
            <Box>{(fare.from as ViviLocation)?.name}</Box>
            <ArrowForward sx={{ fontSize: "1em" }}/>
            <Box>{(fare.to as ViviLocation)?.name}</Box>
          </Stack>
          <Stack direction={"row"} spacing={"0.25em"} alignItems={"center"}>
            <Box>{toDateString(depart)}</Box>
            <Box>:</Box>
            <Box>{toTimeString(depart)}</Box>
            <Box>-</Box>
            <Box>{toTimeString(arrive)}</Box>
          </Stack>
        </Stack>
        <TicketButton initial={0} tickets={[ ticket ]}/>
      </Stack>
    </TicketBubble>
  );
}

export default TicketSummary;
