import { createContext, useContext } from 'react';
import { User } from "../../api/models/Vivi";


const UserContext = createContext<User>({});

export const useUser = () => {
  return useContext(UserContext);
};

export default UserContext;
