import { Box, Fab, Stack } from "@mui/material";
import { Search, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { blue, green, purple, yellow } from "@mui/material/colors";

const Menu = () => {
  const navigate = useNavigate();

  const domain = window.location.hostname.match(/[^.]\.(.*)/)?.[1];

  let bgColor;
  let envName;

  switch (domain) {
    case 'local.mooglest.com':
      envName = 'local';
      bgColor = blue[700];
      break;
    case 'poc.srt.global':
      envName = 'poc';
      bgColor = purple[700];
      break;
    case 'dev.srt.global':
      envName = 'dev';
      bgColor = green[700];
      break;
    case 'test.srt.global':
      envName = 'test';
      bgColor = yellow[800];
  }

  return (
    <Stack spacing={"0.25em"} width={'100vw'}>
      {bgColor &&
        <Box style={{
          backgroundColor: bgColor,
          color: 'white',
          fontWeight: 700,
          fontSize: '1.2em',
          padding: '0.5em',
          textTransform: "uppercase"
        }}>{envName}</Box>}
      <Stack direction={"row"} spacing={"0.5em"} padding={"0.5em"}>
        <Fab color="primary" size={"small"} onClick={() => navigate("/settings/history")}><Settings/></Fab>
        <Fab color="primary" size={"small"} onClick={() => navigate("/search")}><Search/></Fab>
      </Stack>
    </Stack>
  );
};

export default Menu;