import { createContext, useContext } from 'react';
import { MapApi } from "../MapApi";

const GoogleApisContext = createContext<{
  autocomplete: google.maps.places.AutocompleteService,
  places: google.maps.places.PlacesService,
  geocoder: google.maps.Geocoder
  mapApi: MapApi
}>({
  autocomplete: {
    getPlacePredictions: () => new Promise(() => null),
    getQueryPredictions: () => null
  },
  places: {
    findPlaceFromPhoneNumber: () => null,
    findPlaceFromQuery: () => null,
    getDetails: () => null,
    nearbySearch: () => null,
    textSearch: () => null
  },
  geocoder: { geocode: () => new Promise(() => null) },
  mapApi: {
    currentLocation: () => ({ lat: 0, lng: 0 }),
    addMarker: () => null,
    addPolyline: () => null,
    clearMarkers: () => null,
    clearPolylines: () => null,
    clear: () => null,
    markProgress: () => null,
    clearProgress: () => null,
    setPolylineBounds: () => null,
    center: () => null,
    setMe: () => null,
    setHeading: () => null,
    locationWithOffset: () => ({ lat: 0, lng: 0 }),
    updateBounds: () => null
  }
});

export const useGoogleApis = () => {
  return useContext(GoogleApisContext);
};

export default GoogleApisContext;
