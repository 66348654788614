import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let storedToken = window.localStorage.getItem('user');
if (!storedToken) {
  window.localStorage.setItem('user', crypto.randomUUID ? crypto.randomUUID() : '8ed553f8-00f5-41b2-b522-de114a0254b3');
  storedToken = window.localStorage.getItem('user');
}

root.render(
  // <React.StrictMode>
    <App token={storedToken!}/>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
