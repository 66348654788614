import React from 'react';
import { Fare, ViviLocation } from "../api/models/Vivi";
import { Box, Checkbox, IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";
import { ArrowForward, Info } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { UUID } from "../api/models/Base";
import { useToken } from "../lib/context/TokenContext";

const Bubble = styled(Stack)`
  padding: 0.5em 1em;
  border-radius: 0.5em;
  background-color: ${grey[300]};
`;

const Location = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 50%;
`;

const FareSummary = (
  {
    fare,
    fares,
    from,
    to,
    disabled,
    checked = true,
    onFareChange = () => null,
    tripId
  }: {
    fare: Fare,
    fares: Fare[],
    from: ViviLocation,
    to: ViviLocation,
    disabled: boolean,
    checked?: boolean,
    onFareChange?: (active: boolean, fare: Fare) => void,
    tripId?: UUID
  }
) => {
  const token = useToken();
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Bubble spacing={"0.75em"}>
      <Stack>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={"1em"}>
          <Stack direction={"row"} alignItems={"center"} spacing={"1em"} sx={{ overflow: 'hidden' }}>
            <Location>{from?.name}</Location>
            <ArrowForward sx={{ fontSize: "1em" }}/>
            <Location>{to?.name}</Location>
          </Stack>
          <Box>£{fare.price}</Box>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Box sx={{ color: grey[500], fontSize: "0.9em" }}>{fare.name}</Box>
          </Stack>
          <Checkbox disabled={disabled} checked={checked} onChange={(e) => {
            if (e.target.checked !== checked) {
              onFareChange(e.target.checked, fare)
            }
          }}/>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Button disabled={!checked || disabled} sx={{ fontSize: "0.9em" }} onClick={handleClick}>Change Fare</Button>
          <Button disabled={!checked || disabled} sx={{ fontSize: "0.9em" }} onClick={() => {
            return fetch(`${window._env_.VIVI_SERVER_URL}/trips/${tripId}/fares/${fare.id}/pricePredictions`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then((d) => {
              console.log(d)
            })
          }}>See price prediction</Button>
          <IconButton><Info/></IconButton>
        </Stack>
      </Stack>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: 9999999 }}
      >
        {fares.map((fare) =>
          <MenuItem key={fare.id} onClick={() => {
            handleClose();
            onFareChange(checked, fare);
          }}>
            <Stack direction={"row"} spacing={"1em"} justifyContent={"space-between"} sx={{ width: "100%" }}>
              <Box>{fare.name}</Box>
              <Box>£{fare.price}</Box>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </Bubble>
  )
};

export default FareSummary;
