import React, { ReactNode } from 'react';
import { CallingPoint, Leg, TransportType, Trip, ViviLocation } from "../api/models/Vivi";
import { Box, Stack, styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TripCTAButton from "./TripCTAButton";

const Line = styled(Box)`
  border-bottom: 2px solid black;
  width: 100%;
`;

const Marker = styled(Box)`
  border-left: 2px solid black;
  height: 1em;
  width: 0;
`;

const Position = styled("div", {
  shouldForwardProp: (prop) => prop !== "progress"
})<{ progress: number; }>(({ theme, progress }) => `
   position: absolute;
   bottom: 2.5em;
   left: ${progress}%;
   width: 1em;
   height: 1em;
   border-radius: 1em;
   background-color: ${blue[400]};
   border: 2px solid ${blue[100]};
`);

const getModeTitle = (leg: Leg): ReactNode => {
  switch (leg.mode) {
    case TransportType.WALK:
      return 'Walking'
    case TransportType.BUS:
    case TransportType.REPLACEMENT_BUS:
    case TransportType.COACH:
      return 'Bus';
    case TransportType.UNDERGROUND:
      return 'Underground'
    case TransportType.TRAM:
      return 'Tram'
    case TransportType.RAIL:
      return 'Rail';
    default:
      return '???';
  }
};

const LiveLeg = (
  {
    trip,
    leg,
    progress
  }: {
    trip: Trip
    leg: Leg
    progress?: number
  }
) => {
  const stops = (leg.stops as CallingPoint[]).concat([]);

  return (
    <Stack spacing={"1em"} paddingLeft={"1em"} paddingRight={"1em"} paddingBottom={"1em"} sx={{ position: 'relative' }}>
      <span style={{ margin: "auto" }}>{getModeTitle(leg)}</span>
      <Stack direction={"row"} justifyContent={"space-between"} flexGrow={1}>
        {stops.length === 0 && <><Marker/><Line/><Marker/></>}
        {stops.map((s, idx) =>
          <>
            <Marker title={(s.location as ViviLocation).name}/>
            {idx < stops.length - 1 && <Line/>}
          </>)
        }
      </Stack>
      <Box sx={{ position: 'absolute', left: '0.5em', bottom: '1em', right: '1.5em' }}>
        {typeof progress !== 'undefined' && <Position progress={progress}/>}
      </Box>
      {(trip.fares || []).length > 0 && leg.mode === TransportType.RAIL
        ? <TripCTAButton
          key={leg.id}
          trip={trip}
          leg={leg}
          onPurchased={() => {
            if (!trip.follow) {
              // todo follow
              // todo event
            }
          }}
        />
        : <Button disabled variant={"contained"}>No tickets available</Button>
      }
    </Stack>
  )
};

export default LiveLeg;

