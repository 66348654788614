import React, { useEffect, useMemo, useState } from 'react'
import BottomSheet from "../components/BottomSheet";
import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  Fab,
  IconButton,
  Stack,
  TextField,
  ThemeProvider
} from "@mui/material";
import { amber, blue, grey } from "@mui/material/colors";
import { DateTime, SearchLocation } from "../components/Types";
import LocationSearch from "../components/LocationSearch";
import { Apis } from "../api/Config";
import apiFor from "../api/Api";
import { Favourite, Journey, LocationType, TravelMode, ViviLocation } from "../api/models/Vivi";
import { useNavigate, useParams } from "react-router-dom";
import { MarkerType } from "../lib/MapApi";
import { convertTimeZone, leftPad, toFriendlyDateString, toISOString } from "../lib/DateTime";
import { NearMe, PushPin, Star, SwapVert } from "@mui/icons-material";
import FavouritesMenu from "../components/FavouritesMenu";
import { useEventBus } from "../lib/EventBus";
import DateTimePicker from "../components/DateTimePicker";
import { useGoogleApis } from "../lib/context/GoogleApisContext";
import { useToken } from "../lib/context/TokenContext";

const theme = createTheme({
  palette: {
    action: {
      disabledBackground: grey[200],
      disabled: grey[400]
    }
  }
});

function SearchPage() {
  const { id } = useParams<any>();
  const token = useToken();
  const { mapApi, geocoder } = useGoogleApis();
  const eventBus = useEventBus();
  const navigate = useNavigate();
  const [ fromOpen, setFromOpen ] = useState<boolean>(false);
  const [ from, setFrom ] = useState<SearchLocation | undefined>();
  const [ toOpen, setToOpen ] = useState<boolean>(false);
  const [ to, setTo ] = useState<SearchLocation | undefined>();
  const [ dateTime, setDateTime ] = useState<DateTime>({
    mode: TravelMode.DEPART,
    value: convertTimeZone({
      date: new Date(),
      to: "Europe/London"
    })
  });
  const [ dateOpen, setDateOpen ] = useState<boolean>(false);
  const [ favCandidate, setFavCandidate ] = useState<{
    location: ViviLocation,
    name: string,
    complete: (location: SearchLocation) => void
  }>();
  const [ loading, setLoading ] = useState<boolean>(false);

  const journeyApi = apiFor(Apis.Vivi.Journey, { token });
  const favouriteApi = apiFor(Apis.Vivi.Favourite, { token });

  useEffect(() => {
    mapApi.clear();
    if (from) {
      mapApi.addMarker({
          lat: +from!.value!.split(',')[0],
          lng: +from!.value!.split(',')[1]
        },
        MarkerType.Origin
      );
    }
    if (to) {
      mapApi.addMarker({
          lat: +to!.value!.split(',')[0],
          lng: +to!.value!.split(',')[1]
        },
        MarkerType.Destination
      );
    }
  }, [ from, mapApi, to ]);

  useMemo(() => {
    if (id) {
      apiFor(Apis.Vivi.Journey, { token }).get({
        id,
        fields: [ 'from.*', 'to.*', 'mode', 'date' ]
      }).then((journey: Journey) => {
        const fromLocation = journey.from! as ViviLocation;
        const toLocation = journey.to! as ViviLocation;
        setLoading(false);
        setFrom({
          name: fromLocation.name!,
          address: fromLocation.address!,
          type: fromLocation.type!,
          value: fromLocation.value!,
          variant: "result"
        });
        setTo({
          name: toLocation.name!,
          address: toLocation.address!,
          type: toLocation.type!,
          value: toLocation.value!,
          variant: "result"
        });
        setDateTime({
          mode: journey.mode!,
          value: convertTimeZone({
            date: new Date(journey.date!),
            from: "UTC",
            to: "Europe/London"
          })
        })
      });
    } else {
      const location = mapApi.currentLocation();
      geocoder.geocode({ location: location }, (result) => {
        setFrom({
          name: 'Current location',
          address: result?.[0]?.formatted_address || '',
          type: LocationType.GEO_CODE,
          value: `${location.lat}, ${location.lng}`,
          variant: 'current'
        });
        window.setTimeout(mapApi.center, 100);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id, token ]);

  const generateTimeLabel = (dateTime: DateTime) => {
    const now = new Date();
    const prefix = (dateTime.mode === TravelMode.DEPART ? 'Departing' : 'Arriving');
    if (now.getFullYear() === dateTime.value.getFullYear()
      && now.getMonth() === dateTime.value.getMonth()
      && now.getDate() === dateTime.value.getDate()
      && now.getHours() === dateTime.value.getHours()
      && now.getMinutes() === dateTime.value.getMinutes()) {
      return `${prefix} now`;
    }

    const day = now.getFullYear() === dateTime.value.getFullYear() && now.getMonth() === dateTime.value.getMonth() && now.getDate() === dateTime.value.getDate()
      ? ' today'
      : `on ${toFriendlyDateString(dateTime.value)}`;

    const time = `at ${leftPad(dateTime.value.getHours(), 2)}:${leftPad(dateTime.value.getMinutes(), 2)}`;

    return `${prefix} ${day} ${time}`
  };

  const performSearch = () => {
    setLoading(true);
    journeyApi.create({
      obj: {
        from: { ...from, name: from?.variant === 'current' ? from.address : from!.name },
        to: { ...to, name: to?.variant === 'current' ? to.address : to!.name },
        mode: dateTime.mode,
        date: toISOString(convertTimeZone({ date: dateTime.value, from: "Europe/London", to: "UTC" }))
      },
      fields: [ '*', 'from.*', 'to.*' ]
    }).then((j) => navigate(`/results/${j.id}`))
  };

  const onFavouriteClick = (favourite: Favourite) => {
    const location = mapApi.currentLocation();
    geocoder.geocode({ location: new google.maps.LatLng(location) }, (result) => {
      const from: SearchLocation = {
        name: 'Current location',
        address: result?.[0]?.formatted_address || '',
        type: LocationType.GEO_CODE,
        value: `${location.lat}, ${location.lng}`,
        variant: 'current'
      };
      const to: SearchLocation = {
        name: favourite.name!,
        address: favourite.address!,
        type: favourite.type!,
        value: favourite.value!,
        variant: 'favourite'
      };
      setFrom(from);
      setTo(to);
      setDateTime({
        mode: TravelMode.DEPART,
        value: convertTimeZone({ date: new Date(), to: "Europe/London" })
      });

      setLoading(true);
      journeyApi.create({
        obj: {
          from: { ...from, name: from?.variant === 'current' ? from.address : from!.name },
          to: { ...to, name: to?.variant === 'current' ? to.address : to!.name },
          mode: dateTime.mode,
          date: toISOString(convertTimeZone({ date: dateTime.value, from: "Europe/London", to: "UTC" }))
        },
        fields: [ '*', 'from.*', 'to.*' ]
      }).then((j) => navigate(`/results/${j.id}`))
    });
  }

  const addFavourite = (e: React.MouseEvent<HTMLButtonElement>, candidate: ViviLocation, complete: (location: SearchLocation) => void) => {
    e.stopPropagation();
    setFavCandidate({ location: candidate, name: '', complete });
  }

  const swapFromTo = () => {
    setFrom(to)
    setTo(from)
  }

  return (
    <>
      <BottomSheet disableDrag disableBack>
        <Box sx={{ position: 'absolute', left: 0, right: 0, bottom: '17em' }}>
          <FavouritesMenu onClick={onFavouriteClick}/>
        </Box>
        <Stack spacing={"1em"} padding={"1em"}>
          <Stack
            border={`1px solid ${grey[400]}`}
            borderRadius={'0.5em'}
          >
            <Stack
              direction={"row"}
              color={"inherit"}
              onClick={() => setFromOpen(true)}
              className={from ? 'value' : ''}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              {from && from.variant === 'result' &&
                  <IconButton sx={{ fontSize: "1em" }} onClick={(e) => addFavourite(e, from, setFrom)}><PushPin
                      sx={{ fontSize: "1em" }}/></IconButton>
              }
              {from && from.variant === 'favourite' &&
                  <IconButton sx={{ fontSize: "1em" }}><Star sx={{ fontSize: "1em", color: amber[500] }}/></IconButton>
              }
              {from && from.variant === 'current' &&
                  <IconButton sx={{ fontSize: "1em" }} onClick={(e) => addFavourite(e, from, setFrom)}><NearMe
                      sx={{ fontSize: "1em", color: blue[500] }}/></IconButton>
              }
              {from ? <Box color={from.variant === 'current' ? blue[500] : grey[800]}>{from.name}</Box> :
                <Box paddingLeft={"0.5em"} color={grey[500]}>From</Box>}
            </Stack>
            <ThemeProvider theme={theme}>
              <Fab
                disabled={!from && !to}
                color={"default"}
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  position: "absolute",
                  right: "2em",
                  top: "2.75em",
                  boxShadow: "none",
                  border: `1px solid ${grey[400]}`,
                  backgroundColor: `${grey[200]}!`,
                }}
                onClick={swapFromTo}
              ><SwapVert/></Fab>
            </ThemeProvider>
            <Stack
              direction={"row"}
              color={"inherit"}
              onClick={() => setToOpen(true)}
              className={to ? 'value' : ''}
              borderTop={`1px solid ${grey[400]}`}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              {to && to.variant === 'result' &&
                  <IconButton sx={{ fontSize: "1em" }} onClick={(e) => addFavourite(e, to, setTo)}><PushPin
                      sx={{ fontSize: "1em" }}/></IconButton>
              }
              {to && to.variant === 'favourite' &&
                  <IconButton sx={{ fontSize: "1em" }}><Star sx={{ fontSize: "1em", color: amber[500] }}/></IconButton>
              }
              {to && to.variant === 'current' &&
                  <IconButton sx={{ fontSize: "1em" }} onClick={(e) => addFavourite(e, to, setTo)}><NearMe
                      sx={{ fontSize: "1em" }}/></IconButton>
              }
              {to ? <Box color={to.variant === 'current' ? blue[500] : grey[800]}>{to.name}</Box> :
                <Box paddingLeft={"0.5em"} color={grey[500]}>To</Box>}
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            color={"inherit"}
            onClick={() => setDateOpen(true)}
            border={`1px solid ${grey[400]}`}
            borderRadius={"0.5em"}
            height={"1.5em"}
            padding={"0.5em"}
            alignItems={"center"}
          >
            <Box paddingLeft={"0.5em"} color={grey[800]}>{generateTimeLabel(dateTime)}</Box>
          </Stack>
          <Button
            fullWidth
            disabled={!from || !to || loading}
            variant={"contained"}
            onClick={performSearch}
          >
            {loading ? (<CircularProgress size={"1.75em"}/>) : 'Search'}
          </Button>
        </Stack>
        <LocationSearch
          open={fromOpen}
          setOpen={setFromOpen}
          onSelection={setFrom}
          placeholder={"From"}
        />
        <LocationSearch
          open={toOpen}
          setOpen={setToOpen}
          onSelection={setTo}
          placeholder={"To"}
        />
        <DateTimePicker
          open={dateOpen}
          setOpen={setDateOpen}
          initialDateTime={dateTime}
          onSelection={(dateTime) => {
            setDateTime(dateTime);
            setDateOpen(false);
          }}
        />
      </BottomSheet>
      <Dialog fullWidth open={!!favCandidate} onClose={() => setFavCandidate(undefined)} sx={{ zIndex: 99999999 }}>
        <Stack padding={"1em"} spacing={"1em"}>
          <Box>{favCandidate?.location?.address}</Box>
          <TextField
            size={"small"}
            InputLabelProps={{ shrink: true }}
            label="Name"
            placeholder="Name"
            value={favCandidate?.name}
            onChange={(e) => setFavCandidate({ ...favCandidate!, name: e.target.value })}
          />
          <Button variant={"contained"} onClick={() => {
            favouriteApi.create({
              obj: {
                ...favCandidate?.location,
                name: favCandidate!.name,
              }
            }).then((favourite) => {
              eventBus.dispatch('favourites-modified');
              favCandidate?.complete({
                name: favourite.name!,
                address: favourite.address!,
                type: favourite.type!,
                value: favourite.value!,
                variant: 'favourite'
              });
              setFavCandidate(undefined);
            });
          }}>Save Favourite</Button>
        </Stack>
      </Dialog>
    </>
  );
}

export default SearchPage;
