import React, { useCallback, useMemo, useState } from 'react'
import { debounce, TextField, TextFieldProps } from "@mui/material";

const FormikTextField = ({ onChange, value, ...props }: TextFieldProps) => {
  const [current, setCurrent] = useState<unknown | undefined>(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formikUpdate = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e)
      }
    }, 300),
    []
  )

  useMemo(() => {
    setCurrent(value)
  }, [value])

  return (
    <TextField
      {...props}
      value={current}
      onChange={e => {
        setCurrent(e.target.value)
        formikUpdate(e)
      }}
    />
  )
}
export default FormikTextField
