import { useApiList } from "../../api/ApiHooks";
import { Apis } from "../../api/Config";
import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import { SortDirection } from "../../api/Search";
import FavouriteSummary from "../Favourite";
import { useToken } from "../../lib/context/TokenContext";

const Favourites = () => {
  const token = useToken();
  const { data: favouritesPage, loading, refetch } = useApiList(Apis.Vivi.Favourite, { token }, {
    pageSize: 50,
    sort: [
      { field: 'createdAt', direction: SortDirection.Desc }
    ]
  });
  return loading
    ? (
      <Stack alignItems={"center"} justifyContent={"center"}>
        <CircularProgress/>
      </Stack>
    )
    : <Stack spacing={"1em"} overflow={"auto"} maxHeight={"86vh"}>
      {favouritesPage?.content?.map((favourite) => (
        <FavouriteSummary key={favourite.id!} favourite={favourite} token={token} onChange={refetch}/>
      ))}
    </Stack>
};

export default Favourites;