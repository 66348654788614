import { useApiList } from "../../api/ApiHooks";
import { Apis } from "../../api/Config";
import { CircularProgress, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { Operation, SortDirection } from "../../api/Search";
import TripSummary from "../TripSummary";
import { useEventBus } from "../../lib/EventBus";
import { useToken } from "../../lib/context/TokenContext";

const Trips = () => {
  const token = useToken();
  const eventBus = useEventBus();
  const { data: tripsPage, loading, refetch } = useApiList(Apis.Vivi.Trip, { token }, {
    fields: [
      '*',
      'legs.*',
      'fares.*',
      'legs.ticket.*',
      'orders.status',
      'orders.orderItems.tickets.*',
      'orders.orderItems.tickets.fare.*',
      'orders.orderItems.tickets.fare.from.name',
      'orders.orderItems.tickets.fare.to.name'
    ],
    search: [
      { field: 'follow', op: Operation.Equals, value: true }
    ],
    sort: [
      { field: 'depart', direction: SortDirection.Asc }
    ]
  });

  useMemo(() => {
    eventBus.on('trip-changed', refetch)
  }, [ eventBus, refetch ])

  return loading
    ? (
      <Stack alignItems={"center"} justifyContent={"center"}>
        <CircularProgress/>
      </Stack>
    )
    : <Stack spacing={"1em"} overflow={"auto"} maxHeight={"86vh"}>
      {
        tripsPage?.content.map((trip) =>
          <TripSummary
            key={trip.id!}
            trip={trip}
          />
        )}
    </Stack>;
};

export default Trips;
