import React, { useMemo, useState } from 'react'
import { Stack } from "@mui/material";
import { Journey, Leg, Trip, ViviLocation } from "../api/models/Vivi";
import '@splidejs/react-splide/css';
import { useEventBus } from "../lib/EventBus";
import TripSummary from "./TripSummary";
import SearchSummary from "./SearchSummary";
import { MarkerType } from "../lib/MapApi";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import { blue, grey } from "@mui/material/colors";
import { useGoogleApis } from "../lib/context/GoogleApisContext";

function Results({
  journey,
  index,
  onChange = () => null
}: {
  journey: Journey
  index: number
  onChange?: (trip: Trip) => void
}) {
  const { mapApi } = useGoogleApis();
  const eventBus = useEventBus();
  const [ trips, setTrips ] = useState<Trip[]>(journey.trips as Trip[]);

  useMemo(() => {
    eventBus.on('trip-changed', (e) =>
      setTrips(trips.map(t => t.id === (e.data as Trip).id ? (e.data as Trip) : t))
    );
    const fromLocation = journey.from! as ViviLocation;
    const toLocation = journey.to! as ViviLocation;
    mapApi.clear();
    mapApi.addMarker({
        lat: +fromLocation.value!.split(',')[0],
        lng: +fromLocation.value!.split(',')[1]
      },
      MarkerType.Origin
    );
    mapApi.addMarker({
        lat: +toLocation.value!.split(',')[0],
        lng: +toLocation.value!.split(',')[1]
      },
      MarkerType.Destination
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus ]);

  const onSelected = (trip: Trip) => {
    mapApi.clearPolylines();
    trips.filter((t) => t.id !== trip.id!).forEach((t) => {
      (t.legs as Leg[])?.forEach((leg) => {
        mapApi.addPolyline({
            id: leg.id!,
            path: leg.polyline!.split(';').map((p) => {
              const pp = p.split(',');
              return { lat: +pp[0], lng: +pp[1] }
            })
          },
          grey[700]
        );
      });
    });
    onChange(trip);
    (trip.legs as Leg[])?.forEach((leg) => {
      mapApi.addPolyline({
          id: leg.id!,
          path: leg.polyline!.split(';').map((p) => {
            const pp = p.split(',');
            return { lat: +pp[0], lng: +pp[1] }
          })
        },
        blue[600]
      );
    });
  }

  return (
    <Stack spacing={"1em"} padding={"0 1em 0.5em 1em"}>
      <SearchSummary journey={journey}/>
      <Swiper
        style={{ width: "100%" }}
        modules={[ Pagination ]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onInit={(e) => {
          e.activeIndex = index;
          onSelected(trips[e.activeIndex])
        }}
        onSlideChange={(e) => onSelected(trips[e.activeIndex])}
      >
        {(trips as Trip[])!.map((trip) => (
          <SwiperSlide key={trip.id!} style={{ marginBottom: "2em" }}>
            <TripSummary trip={trip}/>
            <div style={{ height: "2em" }}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
}

export default Results;
