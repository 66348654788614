import { Box, IconButton, Link, Stack, styled, Tooltip } from "@mui/material";
import { Journey, Source, ViviLocation } from "../api/models/Vivi";
import { grey } from "@mui/material/colors";
import React, { ReactNode } from "react";
import { AccessTime, ArrowForward, AutoAwesome, NoAccounts, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertTimeZone } from "../lib/DateTime";

const Bubble = styled(Stack)`
  padding: 0.5em 1em;
  border-radius: 0.5em;

  &.view {
    background-color: ${grey[300]};
  }
`;

const Location = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 50%;
`;

const getIconForJourney = (journey: Journey) => {
  const confidence = (Math.round(journey.confidence! * 10000) / 100) + '%'
  const iconStyle = { height: "1em", width: "1em" };
  const popperProps = { style: { zIndex: 9999999 } };

  switch (journey.source) {
    case Source.AI:
      return <Tooltip title={confidence} PopperProps={popperProps}>
        <IconButton style={iconStyle}>
          <AutoAwesome/>
        </IconButton>
      </Tooltip>
    case Source.SCHEDULE:
      return <Tooltip title={confidence} PopperProps={popperProps}>
        <IconButton style={iconStyle}>
          <AccessTime/>
        </IconButton>
      </Tooltip>
    case Source.USER:
      return <Tooltip title={confidence} PopperProps={popperProps}>
        <IconButton style={iconStyle}>
          <Person/>
        </IconButton>
      </Tooltip>
    default:
      return <Tooltip title={confidence} PopperProps={popperProps}>
        <IconButton style={iconStyle}><NoAccounts/>
        </IconButton>
      </Tooltip>
  }
}

const SearchSummary = (
  {
    journey,
    view = false,
    disableEdit = false,
    onClick = () => {
    },
    extra
  }: {
    journey: Journey
    view?: boolean
    disableEdit?: boolean
    onClick?: () => void
    extra?: ReactNode
  }
) => {
  const navigate = useNavigate();
  const fromLocation = journey.from! as ViviLocation;
  const toLocation = journey.to! as ViviLocation;

  const handleClick = () => {
    if (view) {
      navigate(`/results/${journey.id}`);
    } else {
      onClick();
    }
  };

  return (
    <Bubble className={view ? 'view' : ''} spacing={"0.75em"} onClick={handleClick}>
      <Stack justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"} spacing={"1em"}>
          <Box>{getIconForJourney(journey)}</Box>
          <Location>{fromLocation.name}</Location>
          <ArrowForward sx={{ fontSize: "1em" }}/>
          <Location>{toLocation.name}</Location>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={"0.25em"}
            sx={{ color: grey[500], fontSize: "0.9em" }}
          >
            <Box>{journey.mode!.substring(0, 1) + journey.mode!.substring(1).toLowerCase()}</Box>
            <span>at</span>
            <Box>{convertTimeZone({
              date: new Date(journey.date!),
              from: "UTC",
              to: "Europe/London"
            }).toLocaleString()}</Box>
          </Stack>
          {!disableEdit &&
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Link onClick={(e) => {
                e.stopPropagation();
                navigate(`/searches/${journey.id}`)
              }}>Edit</Link>
            </Stack>
          }
        </Stack>
      </Stack>
    </Bubble>
  );
}

export default SearchSummary;