import { Box, IconButton, Stack, styled } from "@mui/material";
import { Fare, Leg, TransportType, Trip } from "../api/models/Vivi";
import {
  ArrowBack,
  ArrowForwardIos,
  DirectionsBus,
  DirectionsTransit,
  DirectionsWalk,
  Favorite,
  FavoriteBorder,
  PlayCircle,
  Subway,
  Tram
} from "@mui/icons-material";
import { blue, grey } from "@mui/material/colors";
import React, { useState } from "react";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";
import { useEventBus } from "../lib/EventBus";
import Sheet from "react-modal-sheet";
import TripDetails from "./TripDetails";
import { useNavigate } from "react-router-dom";
import { convertTimeZone, toTimeString } from "../lib/DateTime";
import TripCTAButton from "./TripCTAButton";
import { useToken } from "../lib/context/TokenContext";

const TripBubble = styled(Stack)`
  background-color: ${grey[300]};
  padding: 1em;
  border-radius: 0.5em;

  &.active {
    background-color: ${blue[100]};
  }
`;

const getLegIcon = (leg: Leg, last: boolean) => {
  let el;
  switch (leg.mode) {
    case TransportType.WALK:
      el = <DirectionsWalk/>
      break;
    case TransportType.BUS:
    case TransportType.REPLACEMENT_BUS:
    case TransportType.COACH:
      el = <DirectionsBus/>
      break;
    case TransportType.UNDERGROUND:
      el = <Subway/>
      break;
    case TransportType.TRAM:
      el = <Tram/>
      break;
    case TransportType.RAIL:
      el = <DirectionsTransit/>;
      break;
    default:
      el = <b>???</b>
  }
  el = <Box sx={{ backgroundColor: grey[100], padding: "0.25em", borderRadius: "0.25em" }}>{el}</Box>;
  return <Stack key={leg.id!} direction={"row"} spacing={"0.1em"} alignItems={"center"}>
    {last ? el : <>{el}<ArrowForwardIos sx={{ fontSize: "1em" }}/></>}
  </Stack>;

}

const TripSummary = (
  {
    trip,
    swipe = false
  }: {
    trip: Trip
    swipe?: boolean
  }
) => {
  const token = useToken();
  const eventBus = useEventBus();
  const navigate = useNavigate();
  const tripApi = apiFor(Apis.Vivi.Trip, { token });
  const [ infoOpen, setInfoOpen ] = useState<boolean>(false);

  const cheapestPrice = trip.fares?.length
    ? (trip.fares as Fare[]).reduce((a, b) =>
        a.price! < b.price! ? a : b
      , { price: Number.MAX_VALUE }).price
    : undefined;

  const depart = convertTimeZone({
    date: new Date(trip.depart!),
    from: "UTC",
    to: "Europe/London"
  });
  const arrive = convertTimeZone({
    date: new Date(trip.arrive!),
    from: "UTC",
    to: "Europe/London"
  });

  const toggleFollow = (trip: Trip) => {
    tripApi.update({
      id: trip.id!,
      obj: {
        follow: !trip.follow
      },
      fields: [ '*', 'legs.*', 'legs.from.name', 'legs.to.name', 'legs.fares', 'orders.status', 'orders.tickets.*' ]
    })
      .then((t) => eventBus.dispatch('trip-changed', t));
  };

  return (
    <TripBubble
      spacing={"0.75em"}
      onClick={() => setInfoOpen(true)}
    >
      <Stack direction={"row"} style={{ fontSize: "1.25em", width: "84vw" }} justifyContent={"space-between"}>
        <Box>{toTimeString(depart)} - {toTimeString(arrive)}</Box>
        {cheapestPrice && <Stack direction={"row"} spacing={"0.25em"} alignItems={"center"}>
          <Box sx={{ color: grey[500], fontSize: "0.75em" }}>from</Box>
          <Box>£{cheapestPrice}</Box>
        </Stack>}
      </Stack>
      <Stack direction={"row"} spacing={"0.1em"} alignItems={"center"}>
        {(trip.legs as Leg[]).map((leg, idx) =>
          getLegIcon(leg, idx === (trip.legs?.length || 0) - 1)
        )}
      </Stack>
      <Stack direction={"row"} spacing={"0.25em"} alignItems={"center"} justifyContent={"space-between"}>
        <Box>{Math.floor(trip.duration! / 60)} h {trip.duration! % 60} min • {trip.changes} changes</Box>
        <Box>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            navigate(`/trips/${trip.id}`);
          }}><PlayCircle/></IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            toggleFollow(trip);
          }}>{trip.follow ? <Favorite sx={{ color: blue[700] }}/> :
            <FavoriteBorder/>}
          </IconButton>
        </Box>
      </Stack>
      <TripCTAButton
        trip={trip}
        swipe={swipe}
        onPurchased={() => {
          if (!trip.follow) {
            toggleFollow(trip);
          } else {
            tripApi.get({
              id: trip.id!,
              fields: [ '*', 'legs.*', 'legs.from.name', 'legs.to.name', 'legs.fares', 'orders.status', 'orders.tickets.*' ]
            }).then((t) => eventBus.dispatch('trip-changed', t));
          }
        }}
      />
      <Sheet
        detent={"full-height"}
        isOpen={infoOpen}
        onClose={() => {
          setInfoOpen(false);
        }}
      >
        <Sheet.Container>
          <Sheet.Header>
            <IconButton
              style={{ padding: "0.5em" }}
              size={"small"}
              onClick={(e) => {
                e.stopPropagation();
                setInfoOpen(false)
              }}
            >
              <ArrowBack/>
            </IconButton>
          </Sheet.Header>
          <Sheet.Content>
            <TripDetails trip={trip}/>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop/>
      </Sheet>
    </TripBubble>
  );
}

export default TripSummary;
