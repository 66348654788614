import React, { useEffect, useMemo, useState } from 'react'
import BottomSheet from "../components/BottomSheet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Apis } from "../api/Config";
import { Journey, Leg, Trip } from "../api/models/Vivi";
import { useApiGet } from "../api/ApiHooks";
import Results from "../components/Results";
import { CircularProgress, Stack } from "@mui/material";
import apiFor from "../api/Api";
import { useEventBus } from "../lib/EventBus";
import { useToken } from "../lib/context/TokenContext";
import { useGoogleApis } from "../lib/context/GoogleApisContext";

function ResultsPage() {
  const { id } = useParams<any>();
  const { search } = useLocation();
  const params = new URLSearchParams(search)
  const selected = params.get('selected') || '';
  const replace = params.get('replace') || '';

  const token = useToken();
  const { mapApi } = useGoogleApis();
  const navigate = useNavigate();
  const eventBus = useEventBus();
  const [ replacing, setReplacing ] = useState<Trip>()

  const tripApi = apiFor(Apis.Vivi.Trip, { token });
  const { data: journey, loading } = useApiGet<Journey>(
    Apis.Vivi.Journey,
    { token },
    {
      id,
      fields: [
        '*',
        'from.*',
        'to.*',
        'trips.duration',
        'trips.depart',
        'trips.arrive',
        'trips.follow',
        'trips.overtaken',
        'trips.legs.*',
        'trips.legs.from.*',
        'trips.legs.to.*',
        'trips.legs.fares.name',
        'trips.fares.*',
        'trips.fares.price',
        'trips.fares.currency',
        'trips.fares.legs',
        'trips.legs.stops.*',
        'trips.legs.stops.location.*',
        'trips.legs.ticket.*',
        'trips.legs.service.*',
        'trips.orders.status',
        'trips.orders.orderItems.tickets.*',
        'trips.orders.orderItems.tickets.fare.from.name',
        'trips.orders.orderItems.tickets.fare.to.name',
        'trips.orders.orderItems.tickets.fare.name',
        'trips.orders.orderItems.tickets.fare.price'
      ]
    }
  );
  const selectedIndex = (journey?.trips as Trip[])?.findIndex((t) => t.id === selected);

  if (journey) {
    (journey.trips as Trip[])?.forEach(trip => {
      if (!(trip.legs![0] as Leg).scheduledDepart) {
        (trip.legs![0] as Leg).scheduledDepart = trip.depart;
        (trip.legs![0] as Leg).scheduledArrive = (trip.legs![1] as Leg).scheduledDepart;
        (trip.legs![trip.legs!.length - 1] as Leg).scheduledArrive = trip.arrive;
        (trip.legs![trip.legs!.length - 1] as Leg).scheduledDepart = (trip.legs![trip.legs!.length - 2] as Leg).scheduledArrive;
      }
    });
  }
  useEffect(() => {
    if (replace) {
      tripApi.get({ id: replace, fields: [ 'legs.polyline' ] }).then(setReplacing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ replace ]);

  useMemo(() => {
    eventBus.on('trip-changed', (e) => {
      if (replace) {
        tripApi.update({ id: replace, obj: { follow: false } }).then(() => {
          navigate("/suggestions")
        })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus ]);

  return (
    <BottomSheet disableDrag>
      {loading
        ? <Stack alignItems={"center"} justifyContent={"center"} minHeight={396}>
          <CircularProgress/>
        </Stack>
        : <Results
          journey={journey!}
          index={typeof selectedIndex !== 'undefined' && selectedIndex !== -1 ? selectedIndex : 0}
          onChange={() => {
            if (replacing) {
              (replacing.legs as Leg[]).forEach((leg) => {
                mapApi.addPolyline({
                  id: leg.id!,
                  path: leg.polyline!.split(';').map((p) => {
                    const pp = p.split(',');
                    return { lat: +pp[0], lng: +pp[1] }
                  })
                }, "red");
              });
            }
          }}
        />
      }
    </BottomSheet>
  );
}

export default ResultsPage;
