import React from 'react'
import BottomSheet from "../components/BottomSheet";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { Container, styled, tabClasses } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import Trips from "../components/settings/Trips";
import Tickets from "../components/settings/Tickets";
import SearchHistory from "../components/settings/SearchHistory";
import { useNavigate, useParams } from "react-router-dom";
import Favourites from "../components/settings/Favourites";
import RailCards from "../components/settings/RailCards";
import Schedules from "../components/settings/Schedules";

const StyledTab = styled(Tab)`
  color: #eee;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  padding: 5px 12px;
  margin: 2px;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-grow: 1;

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${grey[600]};
  }
`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  background-color: ${blue[500]};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  margin-bottom: 1em;
  `,
);

const SettingsPage = () => {
  const { section } = useParams();
  const navigate = useNavigate();

  return (
    <BottomSheet detent="full-height" disableDrag>
      <Container>
        <Tabs value={section} onChange={(e, section) => navigate(`/settings/${section}`)}>
          <StyledTabsList>
            <StyledTab value={'tickets'}>Tickets</StyledTab>
            <StyledTab value={'trips'}>Trips</StyledTab>
            <StyledTab value={'schedules'}>Schedules</StyledTab>
            <StyledTab value={'railcards'}>Rail Cards</StyledTab>
            <StyledTab value={'favourites'}>Favourites</StyledTab>
            <StyledTab value={'history'}>History</StyledTab>
          </StyledTabsList>
          <TabPanel value={'tickets'}>
            <Tickets/>
          </TabPanel>
          <TabPanel value={'trips'}>
            <Trips/>
          </TabPanel>
          <TabPanel value={'schedules'}>
            <Schedules/>
          </TabPanel>
          <TabPanel value={'railcards'}>
            <RailCards/>
          </TabPanel>
          <TabPanel value={'favourites'}>
            <Favourites/>
          </TabPanel>
          <TabPanel value={'history'}>
            <SearchHistory/>
          </TabPanel>
        </Tabs>
      </Container>
    </BottomSheet>
  );
}

export default SettingsPage;