import { createContext, useContext } from 'react';
import { Info } from "../../api/models/Vivi";

const InfoContext = createContext<Info>({});

export const useInfo = () => {
  return useContext(InfoContext);
};

export default InfoContext;
