import { ApizedAuditEntry, ApizedDefinition, Service } from './Api';
import {
  Device,
  Favourite,
  Feedback,
  Info,
  Journey,
  Order,
  PaymentMethod,
  RailCard,
  Route,
  Schedule,
  Ticket,
  Trip,
  User,
  WalletPass
} from "./models/Vivi";

export const Apis = {
  Vivi: {
    AuditInstance: {
      path: '/audit/[entity]/[target]',
      service: Service.Vivi,
    } as ApizedDefinition<ApizedAuditEntry>,
    Info: {
      path: '/info',
      service: Service.Vivi,
    } as ApizedDefinition<Info>,
    Favourite: {
      path: '/favourites',
      service: Service.Vivi,
    } as ApizedDefinition<Favourite>,
    Journey: {
      path: '/journeys',
      service: Service.Vivi,
    } as ApizedDefinition<Journey>,
    Trip: {
      path: '/trips',
      service: Service.Vivi,
    } as ApizedDefinition<Trip>,
    Ticket: {
      path: '/trips/[trip]/legs/[leg]/tickets',
      service: Service.Vivi,
    } as ApizedDefinition<Ticket>,
    Order: {
      path: '/orders',
      service: Service.Vivi,
    } as ApizedDefinition<Order>,
    PaymentMethod: {
      path: '/users/[user]/paymentMethods',
      service: Service.Vivi,
    } as ApizedDefinition<PaymentMethod>,
    GlobalTicket: {
      path: '/tickets',
      service: Service.Vivi,
    } as ApizedDefinition<Ticket>,
    User: {
      path: '/users',
      service: Service.Vivi,
    } as ApizedDefinition<User>,
    Route: {
      path: '/routes',
      service: Service.Vivi,
    } as ApizedDefinition<Route>,
    Schedule: {
      path: '/routes/[route]/schedules',
      service: Service.Vivi,
    } as ApizedDefinition<Schedule>,
    Suggestion: {
      path: '/suggestions',
      service: Service.Vivi,
    } as ApizedDefinition<Trip>,
    RailCard: {
      path: '/railCards',
      service: Service.Vivi,
    } as ApizedDefinition<RailCard>,
    Device: {
      path: '/devices',
      service: Service.Vivi,
    } as ApizedDefinition<Device>,
    Feedback: {
      path: '/journeys/[journey]/feedbacks',
      service: Service.Vivi,
    } as ApizedDefinition<Feedback>,
    WalletPass: {
      path: '/tickets/[ticket]/walletPasses',
      service: Service.Vivi,
    } as ApizedDefinition<WalletPass>
  },
};
