import React, { ReactElement, useEffect, useState } from 'react'
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api';
import { LatLng, MapApi, MarkerType, Polyline } from "../lib/MapApi";
import { CurrentLocationIcon, DestinationIcon, HeadingIcon, OriginIcon } from "../lib/MapIcons";
import GoogleApisContext from "../lib/context/GoogleApisContext";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const libraries: Libraries = [ 'places', 'geocoding' ];

const mapBottomOffset = 400;

function GoogleMapContainer({ token, children }: { token: string, children: ReactElement }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    mapIds: [ 'a77b7337123bef4' ],
    libraries,
    googleMapsApiKey: "AIzaSyDlDV-24MV-ks32VqzgYV58oU1Zd-ImpHQ"
  });

  const [ map, setMap ] = useState(null)
  const [ autocomplete, setAutocomplete ] = useState<google.maps.places.AutocompleteService | undefined>()
  const [ places, setPlaces ] = useState<google.maps.places.PlacesService | undefined>()
  const [ geocoder, setGeocoder ] = useState<google.maps.Geocoder | undefined>()

  let markers: google.maps.Marker[] = [];
  let polylines: google.maps.Polyline[] = [];
  let me: google.maps.Marker;
  let heading: google.maps.Marker;
  let location: LatLng = { lat: 51.50853, lng: -0.12574, simulated: true };

  const mapApi: MapApi = {
    currentLocation: () => location,
    addMarker: (position: LatLng, type: MarkerType) => {
      const marker = new google.maps.Marker({
        position: position,
        icon: type === MarkerType.Destination ? DestinationIcon : OriginIcon
      })
      marker.setMap(map);
      markers.push(marker);
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker.getPosition() as google.maps.LatLng));
      (map as unknown as google.maps.Map)?.fitBounds(bounds, { top: 60, bottom: mapBottomOffset });
    },
    addPolyline: (polyline: Polyline, color: string) => {
      const pol = new google.maps.Polyline({
        path: polyline.path,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      pol.setMap(map);
      polylines.push(pol);
    },
    clearMarkers: () => {
      markers.forEach((m) => m.setMap(null));
      markers = [];
    },
    clearPolylines: () => {
      polylines.forEach((m) => m.setMap(null));
      polylines = [];
    },
    clear: () => {
      mapApi.clearMarkers();
      mapApi.clearPolylines();
    },
    markProgress: (index: number, progress: number) => {
      let polyline = polylines[index];
      let points = polyline.getPath().getArray();
      let pointIdx = Math.floor((progress / 100) * (points.length - 1));
      let point = points[pointIdx];
      mapApi.setMe({ lat: point.lat(), lng: point.lng() });
    },
    clearProgress: () => {
      me.setMap(null);
    },
    setPolylineBounds: (index: number) => {
      const bounds = new google.maps.LatLngBounds();
      const polyline = polylines[index];
      polyline.getPath().forEach((point) => bounds.extend(point));
      (map as unknown as google.maps.Map)?.fitBounds(bounds, { top: 60, bottom: mapBottomOffset });
    },
    setMe: (position: LatLng, center?: boolean) => {
      if (me) {
        me.setPosition(position)
      } else {
        me = new google.maps.Marker({
          position: new google.maps.LatLng(position),
          icon: CurrentLocationIcon,
          optimized: false,
          zIndex: 99999999,
          map,
        });
      }
      if (center) {
        (map as unknown as google.maps.Map).setCenter(mapApi.locationWithOffset(position));
      }
    },
    setHeading: (degrees: number) => {
      if (me && me.getMap()) {
        if (heading) {
          heading.setPosition(me.getPosition())
          heading.setIcon({ ...HeadingIcon, rotation: degrees });
        } else {
          heading = new google.maps.Marker({
            position: new google.maps.LatLng(me.getPosition()!),
            icon: { ...HeadingIcon, rotation: degrees },
            map,
          });
        }
      }
    },
    center: () => {
      (map as unknown as google.maps.Map).setZoom(14);
      (map as unknown as google.maps.Map).setCenter(
        mapApi.locationWithOffset(location)
      )
    },
    locationWithOffset: (location: LatLng): LatLng => {
      const _map = (map as unknown as google.maps.Map);
      if (_map.getProjection()) {
        const point = _map.getProjection()!.fromLatLngToPoint(location)!;
        const offset = new google.maps.Point(
          0,
          mapBottomOffset / Math.pow(2, 1 + _map.getZoom()!)
        );
        let offsetLatLng = _map.getProjection()!.fromPointToLatLng(new google.maps.Point(
          point.x,
          point.y + offset.y
        ))!;
        return { lat: offsetLatLng.lat(), lng: offsetLatLng.lng() };
      }
      return location;
    },
    updateBounds: () => {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker.getPosition() as google.maps.LatLng));
      polylines.forEach((polyline) => polyline.getPath().forEach((point) => bounds.extend(point)));
      (map as unknown as google.maps.Map)?.fitBounds(bounds, { top: 60, bottom: mapBottomOffset });
    }
  };

  const onLoad = React.useCallback(function callback(map: any) {
    setAutocomplete(new google.maps.places.AutocompleteService());
    setPlaces(new google.maps.places.PlacesService(map));
    setGeocoder(new google.maps.Geocoder())
    setMap(map);
    window.map = map;
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  useEffect(() => {
    if (!isLoaded || !map) return;
    navigator.geolocation.watchPosition((position) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      location = { lat: position.coords.latitude, lng: position.coords.longitude }
      mapApi.setMe(
        location,
        !markers.length
      );
      if (position.coords.heading != null) {
        mapApi.setHeading(position.coords.heading!)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoaded, map ])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: 51.50853, lng: -0.12574 }}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapId: 'a77b7337123bef4',
        clickableIcons: false,
        disableDefaultUI: true,
      }}
    >
      {token && map && places && autocomplete && geocoder && (
        <GoogleApisContext.Provider value={{ autocomplete, places, geocoder, mapApi }}>
          {children}
        </GoogleApisContext.Provider>
      )}
    </GoogleMap>
  ) : <></>
}

export default React.memo(GoogleMapContainer)
