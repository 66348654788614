import React, { useState } from 'react';
import { PassType, Ticket } from "../api/models/Vivi";
import Button from "@mui/material/Button";
import BottomSheet from "./BottomSheet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";
import { useToken } from "../lib/context/TokenContext";

// const Location = styled(Box)`
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-width: 50%;
// `;

const TicketButton = (
  {
    tickets,
    initial,
    isOpen = false
  }: {
    initial: number,
    tickets: Ticket[]
    isOpen?: boolean
  }
) => {
  const token = useToken();
  const [ open, setOpen ] = useState(isOpen)

  return <>
    <Button variant={"contained"} onClick={(e) => {
      e.stopPropagation();
      setOpen(true);
    }}>{`Show ticket${tickets.length > 1 ? 's' : ''}`}</Button>
    <BottomSheet
      disableDrag
      disableClose
      isOpen={open}
      detent={"full-height"}
      onClose={() => setOpen(false)}
      onClick={(e) => e.stopPropagation()}
    >
      <Swiper
        style={{ width: "100%", height: "100%" }}
        modules={[ Pagination ]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        initialSlide={initial}
        pagination={{ clickable: true }}
      >
        {tickets.map((ticket) => {
          const byteString = atob(ticket.binary!);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);

          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ ab ], { type: ticket.mimeType });
          const url = URL.createObjectURL(blob);

          return (
            <SwiperSlide key={ticket.id!} style={{ marginBottom: "2em" }}>
              <Button onClick={() => {
                apiFor(Apis.Vivi.WalletPass, { token, ticket }).create({
                  obj: {
                    type: PassType.APPLE
                  }
                }).then(passkit => {
                  const byteString = atob(passkit.pass!);
                  const ab = new ArrayBuffer(byteString.length);
                  const ia = new Uint8Array(ab);
                  for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }

                  const blob = new Blob([ ab ], { type: 'application/vnd.apple.pkpass' });
                  const url = URL.createObjectURL(blob);

                  const a = document.createElement('a');
                  document.body.appendChild(a);
                  a.href = url;
                  a.download = `${ticket.reference}.pkpass`;
                  a.click();
                  setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                  }, 0);
                })
              }}>
                Add to Apple Wallet
              </Button>
              <Button onClick={() => {
                apiFor(Apis.Vivi.WalletPass, { token, ticket }).create({
                  obj: {
                    type: PassType.GOOGLE
                  }
                }).then(passkit => {
                  window.open(passkit.pass, "_blank");
                })
              }}>
                Add to Google Wallet
              </Button>
              <iframe
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Ticket aztec code"
                src={`${url}#zoom=FitW&toolbar=0`}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </BottomSheet>
  </>
};

export default TicketButton;
