import React, { useEffect, useMemo, useState } from 'react'
import BottomSheet from "../components/BottomSheet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Apis } from "../api/Config";
import { Leg, Trip, ViviLocation } from "../api/models/Vivi";
import { useApiGet } from "../api/ApiHooks";
import { Box, Stack } from "@mui/material";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from 'swiper/types/swiper-class';
import LiveLeg from "../components/LiveLeg";
import { MarkerType } from "../lib/MapApi";
import { blue, grey } from "@mui/material/colors";
import { useEventBus } from "../lib/EventBus";
import { useToken } from "../lib/context/TokenContext";
import { useGoogleApis } from "../lib/context/GoogleApisContext";
import { SortDirection } from "../api/Search";

function TripPage() {
  const token = useToken();
  const { mapApi } = useGoogleApis();
  const eventBus = useEventBus();
  const navigate = useNavigate();
  const { id } = useParams<any>();
  const [ ready, setReady ] = useState(false);
  const [ swiper, setSwiper ] = useState<SwiperClass>();
  const [ progress, setProgress ] = useState<number>();
  const [ selected, setSelected ] = useState<number>(0);
  const { data: trip } = useApiGet<Trip>(
    Apis.Vivi.Trip,
    { token },
    {
      id,
      fields: [
        '*',
        'from.*',
        'to.*',
        'legs.*',
        'legs.from.name',
        'legs.to.name',
        'legs.duration',
        'legs.stops.*',
        'legs.ticket.*',
        'fares.name',
        'fares.price',
        'orders.status',
        'orders.tickets.*',
        'orders.tickets.fare.name'
      ],
      sort: [ { field: 'legs.index', direction: SortDirection.Asc } ]
    }
  );
  const legs = ((trip?.legs || []) as Leg[]);

  useMemo(() => {
    if (!trip) return;

    mapApi.clear();
    const fromLocation = trip.from! as ViviLocation;
    const toLocation = trip.to! as ViviLocation;
    mapApi.addMarker({
        lat: +fromLocation.value!.split(',')[0],
        lng: +fromLocation.value!.split(',')[1]
      },
      MarkerType.Origin
    );
    mapApi.addMarker({
        lat: +toLocation.value!.split(',')[0],
        lng: +toLocation.value!.split(',')[1]
      },
      MarkerType.Destination
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ trip ]);

  useEffect(() => {
    if (!trip) return;

    mapApi.clearPolylines();
    legs?.forEach((leg, idx) => {
      mapApi.addPolyline({
          id: leg.id!,
          path: leg.polyline!.split(';').map((p) => {
            const pp = p.split(',');
            return { lat: +pp[0], lng: +pp[1] }
          })
        },
        idx === selected ? blue[600] : grey[700]
      );
    });
    mapApi.setPolylineBounds(selected);
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ trip, selected ]);

  useMemo(() => {
    // feature detect
    let deviceMotionEvent = window.DeviceMotionEvent as any;
    if (typeof deviceMotionEvent?.requestPermission === 'function') {
      deviceMotionEvent.requestPermission()
        .then((permissionState: any) => {
          if (permissionState === 'granted') {
            window.addEventListener('devicemotion', (e) => {
              if (Math.abs(e.accelerationIncludingGravity?.x!) > 30 || Math.abs(e.accelerationIncludingGravity?.y!) > 30) {
                eventBus.dispatch('start-trip-simulation');
              }
            });
            window.addEventListener('deviceorientation', (e) => {
              // mapApi.setHeading((e as unknown as { webkitCompassHeading: number }).webkitCompassHeading)
            });
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus ])

  const simulate = () => {
    if (swiper?.destroyed) return;
    const start = new Date().getTime();
    const total = legs.reduce((sum, leg) => sum + leg.duration!, 0);
    const step = () => {
      if (swiper?.destroyed) return;

      const elapsed = Math.round((new Date().getTime() - start) / 1000);

      let legDuration = 0;
      let legElapsed = elapsed;
      let tmp = elapsed;
      let legIndex = -1;
      legs.forEach((leg, idx) => {
        tmp -= leg.duration!;
        if (legIndex === -1) {
          if (tmp <= 0) {
            legDuration = leg.duration!;
            legIndex = idx;
          } else {
            legElapsed -= leg.duration!;
          }
        }
      });

      if (swiper?.activeIndex !== legIndex) {
        swiper?.slideTo(legIndex);
      }

      let newProgress = Math.round(100 * legElapsed / legDuration);
      setProgress(newProgress);
      mapApi.markProgress(legIndex, newProgress);
      if (elapsed < total) {
        setTimeout(step, 500);
      } else {
        mapApi.clearProgress();
        // setProgress(undefined);
        navigate('/search')
      }
    }
    step();
  }

  useMemo(() => {
    if (legs.length && eventBus && swiper && simulate) {
      eventBus.remove('start-trip-simulation', simulate);
      eventBus.on('start-trip-simulation', simulate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus, legs, swiper ]);

  const { search } = useLocation();
  const params = new URLSearchParams(search)
  const selectedIndex = (trip?.legs as Leg[])?.findIndex((l) => l.id === params.get('selected') || '');

  return (
    <BottomSheet disableDrag>
      {ready && trip && <Box>
          <Stack spacing={"1em"} padding={"1em 0"}>
              <Swiper
                  initialSlide={typeof selectedIndex !== 'undefined' && selectedIndex !== -1 ? selectedIndex : 0}
                  style={{ width: "100%" }}
                  modules={[ Pagination ]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  onSwiper={setSwiper}
                  onInit={(e) => {
                    setSelected(e.activeIndex);
                  }}
                  onSlideChange={(e) => {
                    mapApi.setPolylineBounds(e.activeIndex);
                    setSelected(e.activeIndex);
                  }}
              >
                {legs.map((leg, idx) => (
                  <SwiperSlide key={leg.id!} style={{ marginBottom: "2em" }}>
                    <LiveLeg
                      trip={trip}
                      leg={leg}
                      progress={swiper?.activeIndex === idx ? progress : undefined}
                    />
                    <div style={{ height: "2em" }}></div>
                  </SwiperSlide>
                ))}
              </Swiper>
          </Stack>
      </Box>}
    </BottomSheet>
  );
}

export default TripPage;
