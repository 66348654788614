export enum MarkerType {
  Origin,
  Destination,
}

export type LatLng = { lat: number, lng: number, simulated?: boolean };

export type Polyline = { id: string, path: LatLng[] };

export type MapApi = {
  currentLocation: () => LatLng
  addMarker: (position: LatLng, type: MarkerType) => void,
  addPolyline: (polyline: Polyline, color: string) => void
  clearMarkers: () => void
  clearPolylines: () => void
  clear: () => void
  markProgress: (index: number, progress: number) => void
  clearProgress: () => void
  setPolylineBounds: (index: number) => void
  center: () => void
  setMe: (position: LatLng, center?: boolean) => void
  setHeading: (degrees: number) => void
  locationWithOffset: (location: LatLng) => LatLng
  updateBounds: () => void
}