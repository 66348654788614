import {
  Address,
  Customer,
  Fare,
  Order,
  OrderItem,
  OrderStatus,
  PaymentMethod,
  Transaction,
  TransactionStatus,
  Trip,
  ViviLocation
} from "../../api/models/Vivi";
import React, { FormEvent, useEffect, useState } from "react";
import FareSummary from "../FareSummary";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import apiFor from "../../api/Api";
import { Apis } from "../../api/Config";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import countries from "../../lib/countries";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikTextField from "../FormikTextField";
import { ConfirmCardPaymentData } from "@stripe/stripe-js";
import { PaymentRequestCompleteStatus } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { useToken } from "../../lib/context/TokenContext";
import { useUser } from "../../lib/context/UserContext";

interface StripeOnChangeType extends FormEvent<HTMLInputElement> {
  complete: boolean
  empty: boolean
  error?: { message?: string }
}

const StripeInput = React.forwardRef((props: InputBaseComponentProps, ref) => {
  const { component: Component, ...other } = props;
  React.useImperativeHandle(ref, () => ({
    focus: () => {
    },
  }));
  return <Component {...other} />;
});
const StripeCheckout = ({
  swipe,
  order: receivedOrder,
  paymentMethods,
  onComplete
}: {
  swipe: boolean
  order: Order
  paymentMethods: PaymentMethod[]
  onComplete: () => void
}) => {
  const token = useToken();
  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const [ loading, setLoading ] = useState(swipe);
  const [ order, setOrder ] = useState(receivedOrder);
  const [ paymentMethod, setPaymentMethod ] = useState(paymentMethods.find(pm => user.defaultPaymentMethod === pm.id)?.id || 'wallet');
  const orderApi = apiFor(Apis.Vivi.Order, { token });

  const fares = (order.trip as Trip).fares as Fare[];
  const fare = fares.find(f => f.id === (order?.orderItems as OrderItem[])?.[0]?.fare as string)!;

  const paymentRequest = stripe?.paymentRequest({
    country: 'GB',
    currency: 'gbp',
    displayItems: [ {
      amount: Math.round(fare.price! * 100),
      label: fare.name!,
    } ],
    requestPayerEmail: true,
    requestPayerName: true,
    total: {
      amount: order!.total!,
      label: `Order ${order?.reference || ''}`,
    },
    disableWallets: [ "link", "browserCard" ]
  });
  paymentRequest?.canMakePayment()
    .then(result => {
      if (result) {
        paymentRequest.on('paymentmethod', (event => {
          const { paymentMethod, complete } = event
          onPay({
            orderItems: [ { fare: fare.id } ],
            customer: {
              address: {
                city: paymentMethod.billing_details.address?.city || '',
                country: paymentMethod.billing_details.address?.country || '',
                id: ((order?.customer as Customer)?.address as Address)?.id || undefined,
                line1: paymentMethod.billing_details.address?.line1 || '',
                line2: paymentMethod.billing_details.address?.line2 || undefined,
                postalCode: paymentMethod.billing_details.address?.postal_code || 'N/A',
              },
              email: paymentMethod.billing_details.email || '',
              name: paymentMethod.billing_details.name || '',
              id: (order?.customer as Customer)?.id || undefined,
            },
            status: OrderStatus.COMPLETE,
          }, {
            payment_method: paymentMethod.id,
          }, (status) => {
            complete(status);
            onComplete();
          });
        }));
      }
      return true
    })
    .catch(() => 'Do nothing')

  useEffect(() => {
    if (swipe) {
      onPay({ paymentMethod }, {}, onComplete);
    } else if (order!.total === order!.paid) {
      onPay({}, {}, onComplete);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ swipe ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (order?.customer as Customer)?.email,
      postalCode: ((order?.customer as Customer)?.address as Address)?.postalCode,
      country: ((order?.customer as Customer)?.address as Address)?.country || 'GB',
      cardNumber: undefined,
      cardNumberError: 'Card number is required',
      cardExpiryDate: undefined,
      cardExpiryDateError: 'Expiry date is required',
      cardCvv: undefined,
      cardCvvError: 'CVV is required',
      saveCard: true
    },
    onSubmit: async (values) => {
      onPay({
        customer: {
          email: formik.values.email,
          address: {
            postalCode: formik.values.postalCode,
            country: formik.values.country
          }
        }
      }, {
        payment_method: {
          card: cardNumberElement!,
        },
        setup_future_usage: formik.values.saveCard ? 'on_session' : undefined
      }, onComplete)
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      cardNumber: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardNumberError) {
            return validationContext?.createError({
              message: parent?.cardNumberError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      cardExpiryDate: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardExpiryDateError) {
            return validationContext?.createError({
              message: parent?.cardExpiryDateError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      cardCvv: Yup.string().test({
        exclusive: false,
        test: (_value, validationContext) => {
          const parent = validationContext.parent
          if (parent?.cardCvvError) {
            return validationContext?.createError({
              message: parent?.cardCvvError,
              path: validationContext?.path,
            })
          }
          return true
        },
      }),
      email: Yup.string().email('Please enter a valid email address').required('Email is required'),
      postalCode: Yup.string()
        .trim()
        .min(2, 'Postal code is too short')
        .required('Please enter your postal code'),
      country: Yup.string().required('Please select a country'),
    }),
  });

  const cardNumberElement = elements?.getElement(CardNumberElement);
  const onPay = (orderUpdate: Order, data: ConfirmCardPaymentData, cb: (status: PaymentRequestCompleteStatus) => void = () => null) => {
    setLoading(true);
    orderApi.update({
      id: order.id!,
      obj: { ...orderUpdate, status: OrderStatus.COMPLETE },
      fields: [ 'transactions.*' ]
    }).then((updatedOrder) => {
      const transaction = (updatedOrder.transactions as Transaction[]).find((t) => t.status === TransactionStatus.PENDING);
      if (transaction) {
        const clientSecret = (transaction.metadata as { client_secret: string }).client_secret;
        stripe?.confirmCardPayment(clientSecret, data)
          .then((response) => {
            orderApi.update({
              id: order.id!,
              obj: { status: OrderStatus.COMPLETE },
              fields: [ 'transactions.*' ]
            }).then(() => {
              setLoading(false);
              cb('success')
            });
          });
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <Stack spacing={"1em"} style={{ overflow: 'auto', height: '100%' }} justifyContent={"space-between"}>
      <Stack spacing={"1em"}>
        <Stack spacing={"1em"}>
          <FareSummary
            disabled={loading}
            fare={fare}
            fares={fares}
            tripId={(order.trip as Trip).id}
            checked={true}
            onFareChange={(active, fare) => {
              setLoading(true);
              orderApi.update({
                id: order.id!,
                fields: [
                  '*',
                  'transactions.*',
                  'trip.journeyId',
                  'tickets.fare',
                  'tickets.legs',
                  'trip.legs.mode',
                  'trip.fares.from.name',
                  'trip.fares.to.name',
                  'trip.fares.name',
                  'trip.fares.price',
                  'trip.fares.legs',
                ],
                obj: { orderItems: [ { fare: fare.id, } ] }
              }).then((o) => {
                setOrder(o);
                setLoading(false);
              })
            }}
            from={fare.from as ViviLocation}
            to={fare.to as ViviLocation}
          />
        </Stack>
        <FormControl>
          <InputLabel id="payment-method">Payment Method</InputLabel>
          <Select
            disabled={loading}
            autoComplete="payment-method"
            labelId="payment-method"
            label="Payment Method"
            name="payment-method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            MenuProps={{ style: { zIndex: 9999999 } }}
          >
            <MenuItem value={"wallet"}>{!!window.ApplePaySession ? 'Apple' : 'Google'} Pay</MenuItem>
            {paymentMethods.map((pm) => (
              <MenuItem key={pm.id} value={pm.id}>{pm.descriptor.brand} {pm.descriptor.display}</MenuItem>
            ))}
            <MenuItem value={"card"}>Card</MenuItem>
          </Select>
        </FormControl>
        {paymentMethod === 'card' &&
          <>
            <FormikTextField
              required
              disabled={loading}
              autoComplete="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email address"
              name="email"
              type="email"
            />
            <TextField
              fullWidth
              required
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  onChange: (event: StripeOnChangeType) => {
                    if (event.empty) {
                      return formik.setFieldValue('cardNumberError', 'Card number is required', true)
                    } else if (event.error) {
                      return formik.setFieldValue('cardNumberError', event.error.message, true)
                    } else {
                      return formik.setFieldValue('cardNumberError', undefined, true)
                    }
                  },
                  options: {
                    disabled: loading,
                    style: {
                      base: {
                        fontSize: '20px',
                        color: loading ? 'rgba(0, 0, 0, 0.38)' : 'initial'
                      },
                    }
                  },
                },
              }}
              error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}
              label="Card number"
              name="cardNumber"
            />
            <Stack direction={"row"} spacing={"1em"}>
              <TextField
                fullWidth
                required
                disabled={loading}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    onChange: (event: StripeOnChangeType) => {
                      if (event.empty) {
                        return formik.setFieldValue('cardExpiryDateError', 'Expiry date is required', true)
                      } else if (event.error) {
                        return formik.setFieldValue('cardExpiryDateError', event.error.message, true)
                      } else {
                        return formik.setFieldValue('cardExpiryDateError', undefined, true)
                      }
                    },
                    options: {
                      disabled: loading,
                      style: {
                        base: {
                          fontSize: '20px',
                          color: loading ? 'rgba(0, 0, 0, 0.38)' : 'initial'
                        },
                      }
                    },
                  },
                }}
                error={formik.touched.cardExpiryDate && Boolean(formik.errors.cardExpiryDate)}
                helperText={formik.touched.cardExpiryDate && formik.errors.cardExpiryDate}
                label="Expiry date"
                name="cardExpiryDate"
              />
              <TextField
                fullWidth
                required
                disabled={loading}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    onChange: (event: StripeOnChangeType) => {
                      if (event.empty) {
                        return formik.setFieldValue('cardCvvError', 'CVV is required', true)
                      } else if (event.error) {
                        return formik.setFieldValue('cardCvvError', event.error.message, true)
                      } else {
                        return formik.setFieldValue('cardCvvError', undefined, true)
                      }
                    },
                    options: {
                      disabled: loading,
                      style: {
                        base: {
                          fontSize: '20px',
                          color: loading ? 'rgba(0, 0, 0, 0.38)' : 'initial'
                        },
                      }
                    },
                  },
                }}
                error={formik.touched.cardCvv && Boolean(formik.errors.cardCvv)}
                helperText={formik.touched.cardCvv && formik.errors.cardCvv}
                label="CVC"
                name="cardCvv"
              />
            </Stack>
            <FormikTextField
              required
              disabled={loading}
              autoComplete="postal-code"
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              label="Postal code"
              name="postalCode"
            />
            <FormControl>
              <InputLabel id="country">Country</InputLabel>
              <Select
                required
                disabled={loading}
                autoComplete="country"
                error={formik.touched.country && Boolean(formik.errors.country)}
                labelId="country"
                label="Choose a country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                MenuProps={{ style: { zIndex: 9999999 } }}
              >
                <MenuItem value={"GB"}>
                  <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                    <Box>
                      <img
                        loading="lazy"
                        style={{ width: '1.25em' }}
                        src={'https://flagcdn.com/w20/gb.png'}
                        srcSet={'https://flagcdn.com/w40/gb.png 2x'}
                        alt=""
                      />
                    </Box>
                    <Box>
                      United Kingdom
                    </Box>
                  </Stack>
                </MenuItem>
                {countries.filter(c => c.code !== 'GB')
                  .map((c) => <MenuItem key={c.code} value={c.code}>
                    <Stack direction={"row"} spacing={"1em"} alignItems={"center"}>
                      <Box>
                        <img
                          loading="lazy"
                          style={{ width: '1.25em' }}
                          src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      </Box>
                      <Box>
                        {c.label}
                      </Box>
                    </Stack>
                  </MenuItem>)
                }
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
            </FormControl>
            <FormControl fullWidth required component="fieldset" margin="dense">
              <FormGroup row aria-label="position">
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={formik.values.saveCard}
                      color="secondary"
                      name="saveCard"
                      onChange={formik.handleChange}
                    />
                  }
                  label="Save card"
                  value="No"
                />
              </FormGroup>
            </FormControl>
          </>
        }
      </Stack>
      <Button
        disabled={loading}
        variant={"contained"}
        onClick={() => {
          switch (paymentMethod) {
            case 'wallet':
              paymentRequest?.show();
              break;
            case 'card':
              formik.submitForm();
              break;
            default:
              onPay({ paymentMethod }, {}, onComplete);
          }
        }}>
        {loading ? (<CircularProgress size={"1.75em"}/>) : `Pay ${order.total! / 100}`}
      </Button>
    </Stack>
  );
};

export default StripeCheckout;
