import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  styled
} from "@mui/material";
import { Direction, Favourite, Frequency, Route, Schedule, TravelMode } from "../../api/models/Vivi";
import React, { useState } from "react";
import { deepPurple, grey, red } from "@mui/material/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import LocationSearch from "../LocationSearch";
import { ChevronLeft } from "@mui/icons-material";
import TimePicker from "../TimePicker";
import { toTimeISOString, toTimeString } from "../../lib/DateTime";
import { DateTime } from "../Types";

const WeekDay = styled(Stack)`
  background-color: ${deepPurple[50]};
  border-radius: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  cursor: pointer;

  span {
    margin-top: 0.45em;
  }

  &.active {
    border-radius: 0.75em;
    color: white;
    background-color: ${deepPurple[400]};
  }
`;

const Schedules = ({
  selected,
  onCancel,
  onSave,
}: {
  selected: Route,
  onCancel: () => void,
  onSave: (route: Route) => void
}) => {
  const [ fromOpen, setFromOpen ] = useState<boolean>(false);
  const [ toOpen, setToOpen ] = useState<boolean>(false);
  const [ outboundOpen, setOutboundOpen ] = useState<boolean>(false);
  const [ inboundOpen, setInboundOpen ] = useState<boolean>(false);


  const now = new Date();
  const outbound = (selected.schedules as Schedule[])?.find((s) => s.direction === Direction.OUTBOUND)
  const inbound = (selected.schedules as Schedule[])?.find((s) => s.direction === Direction.INBOUND)
  console.log(now.toISOString().substring(0, 10) + outbound?.time)
  const formik = useFormik({
    initialValues: {
      id: selected.id,
      values: (((selected.schedules || [ {} ]) as Schedule[])?.[0]).values || [],
      from: selected.from as Favourite,
      to: selected.to as Favourite,
      outbound: {
        mode: outbound?.mode || TravelMode.ARRIVE,
        value: outbound ? new Date(now.toISOString().substring(0, 10) + 'T' + outbound.time!) : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0)
      } as DateTime,
      inbound: {
        mode: inbound?.mode || TravelMode.DEPART,
        value: inbound ? new Date(now.toISOString().substring(0, 10) + 'T' + inbound.time!) : new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 30)
      } as DateTime
    },
    onSubmit: async (values) => {
      onSave({
        id: values.id,
        from: { ...values.from, variant: undefined },
        to: { ...values.to, variant: undefined },
        schedules: [
          {
            id: outbound?.id,
            frequency: Frequency.WEEK,
            cadence: 1,
            direction: Direction.OUTBOUND,
            end: undefined,
            mode: values.outbound.mode,
            start: '1970-01-01',
            time: toTimeISOString(values.outbound.value),
            values: values.values
          },
          {
            id: inbound?.id,
            frequency: Frequency.WEEK,
            cadence: 1,
            direction: Direction.INBOUND,
            end: undefined,
            mode: values.inbound.mode,
            start: '1970-01-01',
            time: toTimeISOString(values.inbound.value),
            values: values.values
          }
        ]
      } as Route);
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      from: Yup.object().required("Starting point is required"),
      to: Yup.object().required("End is required"),
      outbound: Yup.object().required("Outbound is required"),
      inbound: Yup.object().required("Return is required"),
      values: Yup.array().min(1, "At least one day is required")
    }),
  });

  console.log(formik.values)

  const handleWeekday = (number: number) => {
    formik.setFieldTouched("values", true);
    if (formik.values.values.indexOf(number) === -1) {
      formik.setFieldValue("values", formik.values.values.concat([ number ]));
    } else {
      const numbers = formik.values.values.concat([]);
      numbers.splice(formik.values.values.indexOf(number), 1);
      formik.setFieldValue("values", numbers);
    }
  }

  return <Stack height={"100%"} spacing={"1em"}>
    <div style={{ fontSize: "1.5em", fontWeight: "600" }}>
      <span><IconButton onClick={onCancel}><ChevronLeft/></IconButton></span>
      {selected.id ? 'Update' : 'Create'} schedule
    </div>
    <span style={{ fontWeight: "100" }}>Add details of regular travel. For example, your commute.</span>
    <Stack spacing={"0.5em"}>
      <FormControl fullWidth required component="fieldset" margin="normal">
        <FormGroup>
          <FormLabel required error={formik.touched.values && !!formik.errors.values}>Days</FormLabel>
          <FormControl>
            <Stack direction={"row"} spacing={"1em"}>
              <WeekDay
                onClick={() => handleWeekday(1)}
                className={formik.values.values.indexOf(1) !== -1 ? "active" : ""}
              >
                <span>M</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(2)}
                className={formik.values.values.indexOf(2) !== -1 ? "active" : ""}
              >
                <span>T</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(3)}
                className={formik.values.values.indexOf(3) !== -1 ? "active" : ""}
              >
                <span>W</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(4)}
                className={formik.values.values.indexOf(4) !== -1 ? "active" : ""}
              >
                <span>T</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(5)}
                className={formik.values.values.indexOf(5) !== -1 ? "active" : ""}
              >
                <span>F</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(6)}
                className={formik.values.values.indexOf(6) !== -1 ? "active" : ""}
              >
                <span>S</span>
              </WeekDay>
              <WeekDay
                onClick={() => handleWeekday(7)}
                className={formik.values.values.indexOf(7) !== -1 ? "active" : ""}
              >
                <span>S</span>
              </WeekDay>
            </Stack>
          </FormControl>
          <FormHelperText
            error={true}
            dangerouslySetInnerHTML={{ __html: (formik.touched.values ? formik.errors.values || '' : '') + '&nbsp;' }}
          />
        </FormGroup>
      </FormControl>
      <FormControl fullWidth required component="fieldset" margin="normal">
        <FormGroup>
          <FormLabel required error={formik.touched.from && !!formik.errors.from}>Starting point</FormLabel>
          <FormControl>
            <Stack
              border={`1px solid ${formik.touched.from && formik.errors.from ? red[700] : grey[400]}`}
              borderRadius={'0.5em'}
              direction={"row"}
              color={"inherit"}
              onClick={() => setFromOpen(true)}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              <Box>{formik.values.from?.name}</Box>
            </Stack>
          </FormControl>
          <FormHelperText
            error={true}
            dangerouslySetInnerHTML={{ __html: (formik.touched.from ? formik.errors.from || '' : '') + '&nbsp;' }}
          />
        </FormGroup>
      </FormControl>
      <FormControl fullWidth required component="fieldset" margin="normal">
        <FormGroup>
          <FormLabel required error={formik.touched.to && !!formik.errors.to}>End</FormLabel>
          <FormControl>
            <Stack
              border={`1px solid ${formik.touched.to && formik.errors.to ? red[700] : grey[400]}`}
              borderRadius={'0.5em'}
              direction={"row"}
              color={"inherit"}
              onClick={() => setToOpen(true)}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              <Box>{formik.values.to?.name}</Box>
            </Stack>
          </FormControl>
          <FormHelperText
            error={true}
            dangerouslySetInnerHTML={{ __html: (formik.touched.to ? formik.errors.to || '' : '') + '&nbsp;' }}
          />
        </FormGroup>
      </FormControl>
      <FormControl fullWidth required component="fieldset" margin="normal">
        <FormGroup>
          <FormLabel required error={formik.touched.outbound && !!formik.errors.outbound}>Outbound</FormLabel>
          <FormControl>
            <Stack
              border={`1px solid ${formik.touched.outbound && formik.errors.outbound ? red[700] : grey[400]}`}
              borderRadius={'0.5em'}
              direction={"row"}
              color={"inherit"}
              onClick={() => setOutboundOpen(true)}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              <Box>{(formik.values.outbound.mode === TravelMode.DEPART ? 'Leave:' : 'Arrive:') + ' ' + toTimeString(formik.values.outbound.value)}</Box>
            </Stack>
          </FormControl>
          <FormHelperText
            error={true}
            dangerouslySetInnerHTML={{ __html: (formik.touched.outbound ? formik.errors.outbound || '' : '') + '&nbsp;' }}
          />
        </FormGroup>
      </FormControl>
      <FormControl fullWidth required component="fieldset" margin="normal">
        <FormGroup>
          <FormLabel required error={formik.touched.inbound && !!formik.errors.inbound}>Return</FormLabel>
          <FormControl>
            <Stack
              border={`1px solid ${formik.touched.inbound && formik.errors.inbound ? red[700] : grey[400]}`}
              borderRadius={'0.5em'}
              direction={"row"}
              color={"inherit"}
              onClick={() => setInboundOpen(true)}
              height={"1.5em"}
              padding={"0.5em"}
              alignItems={"center"}
            >
              <Box>{(formik.values.inbound.mode === TravelMode.DEPART ? 'Leave:' : 'Arrive:') + ' ' + toTimeString(formik.values.inbound.value)}</Box>
            </Stack>
          </FormControl>
          <FormHelperText
            error={true}
            dangerouslySetInnerHTML={{ __html: (formik.touched.inbound ? formik.errors.inbound || '' : '') + '&nbsp;' }}
          />
        </FormGroup>
      </FormControl>
      <Button variant={"contained"} onClick={formik.submitForm}>Save schedule</Button>
    </Stack>
    <LocationSearch
      open={fromOpen}
      setOpen={setFromOpen}
      onSelection={(from) => {
        formik.setFieldTouched("from", true);
        formik.setFieldValue("from",
          from.variant === 'favourite' ? from : {
            ...from, name: prompt("What shall we call this location", from.name)
          }
        );
      }}
      placeholder={"From"}
    />
    <LocationSearch
      open={toOpen}
      setOpen={setToOpen}
      onSelection={(to) => {
        formik.setFieldTouched("to", true);
        formik.setFieldValue("to", to.variant === 'favourite' ? to : {
            ...to, name: prompt("What shall we call this location", to.name)
          }
        );
      }}
      placeholder={"To"}
    />
    <TimePicker
      open={outboundOpen}
      setOpen={setOutboundOpen}
      initialTime={formik.values.outbound}
      onSelection={(time) => {
        formik.setFieldValue("outbound", time);
        formik.setFieldTouched("outbound", true, true);
        setOutboundOpen(false);
      }}
    />
    <TimePicker
      open={inboundOpen}
      setOpen={setInboundOpen}
      initialTime={formik.values.inbound}
      onSelection={(time) => {
        formik.setFieldValue("inbound", time);
        formik.setFieldTouched("inbound", true, true);
        setInboundOpen(false);
      }}
    />
  </Stack>;
}
export default Schedules;