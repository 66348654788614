import { Box, Divider, List, ListItemButton, ListSubheader, Stack, TextField } from "@mui/material";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { SearchLocation } from "./Types";
import { useDebouncedCallback } from "use-debounce";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";
import { Operation } from "../api/Search";
import { FavouriteVariant, LocationType } from "../api/models/Vivi";
import BottomSheet from "./BottomSheet";
import { blue, grey } from "@mui/material/colors";
import { AutoAwesome, LocationOn, NearMe, Star } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useToken } from "../lib/context/TokenContext";
import { useGoogleApis } from "../lib/context/GoogleApisContext";

const LocationSearch = (
  {
    open,
    setOpen,
    onSelection,
    placeholder,
  }: {
    open: boolean
    setOpen: (open: boolean) => void
    onSelection: (location: SearchLocation) => void
    placeholder: string
  }
) => {
  const token = useToken();
  const { mapApi, autocomplete, places, geocoder } = useGoogleApis();
  const [ favourites, setFavourites ] = useState<SearchLocation[]>([]);
  const [ text, setText ] = useState('');
  const [ results, setResults ] = useState<SearchLocation[]>([]);
  const input = useRef<HTMLInputElement>();
  const favouritesApi = apiFor(Apis.Vivi.Favourite, { token });

  const getFavourites = (value?: string): Promise<SearchLocation[]> => {
    return new Promise<SearchLocation[]>((resolve, reject) => {
      favouritesApi.list({
        pageSize: 5,
        search: value ? [ { field: 'name', op: Operation.Like, value } ] : [],
        params: {
          location: mapApi.currentLocation().simulated ? '' : `${mapApi.currentLocation().lat},${mapApi.currentLocation().lng}`
        }
      })
        .then((favPage) => {
          resolve(favPage.content.map((fav) => ({
            name: fav.name!,
            address: fav.address!,
            type: fav.type!,
            value: fav.value!,
            variant: fav.variant === FavouriteVariant.STORED ? 'favourite' : 'suggestion'
          })));
        })
        .catch(reject);
    });
  }

  const getResults = (value: string): Promise<SearchLocation[]> => {
    return new Promise<SearchLocation[]>((resolve, reject) => {
      autocomplete.getPlacePredictions(
        { input: value, origin: mapApi.currentLocation(), componentRestrictions: { country: 'UK' } },
        (results) => {
          Promise.all(results
            ?.sort((a, b) => (a.distance_meters || 0) - (b.distance_meters || 0))
            ?.map((r) => {
              return new Promise<SearchLocation>((resolve1) => {
                places.getDetails({
                  placeId: r.place_id!,
                  fields: [ 'geometry.location', 'formatted_address' ]
                }, (result) => {
                  resolve1({
                    name: r.description!,
                    address: result?.formatted_address || '',
                    type: LocationType.GEO_CODE,
                    value: `${result?.geometry?.location?.lat()}, ${result?.geometry?.location?.lng()}`,
                    variant: 'result',
                    distance: r.distance_meters
                  });
                })
              });
            }) as Promise<SearchLocation>[])
            .then(resolve);
        }
      )
    });
  }

  const search = (value: string) => {
    setText(value);
    setResults([]);
    if (value) {
      Promise.all([ getFavourites(value), getResults(value) ]).then(([ favourites, results ]) => {
        setResults(
          ([] as SearchLocation[])
            .concat(favourites.filter(f => f.variant === "suggestion"))
            .concat(favourites.filter(f => f.variant === "favourite"))
            .concat(results)
        );
      });
    }
  };

  const debounced = useDebouncedCallback(
    (value: string) => {
      search(value);
    },
    300
  );

  useMemo(() => {
    if (open) {
      getFavourites('').then((favourites) => {
        setFavourites(favourites);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ open ]);

  const renderResult = (loc: SearchLocation): ReactNode => {
    return <ListItemButton
      key={loc.value!}
      sx={{ padding: '0.25em 1em' }}
      onClick={() => {
        onSelection(loc);
        setOpen(false);
      }}>
      <Stack direction={"row"} width={"100%"} alignItems={"center"} minHeight={"2em"}>
        {loc.variant === 'result' && <LocationOn/>}
        {loc.variant === 'suggestion' && <AutoAwesome/>}
        {loc.variant === 'favourite' && <Star/>}
        <Stack width={"100%"}>
          <Box sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            overflow: 'hidden'
          }}>{loc.name}</Box>
          {loc.variant === 'result' && <Box sx={{ fontSize: '0.75em', color: grey[500] }}>{loc.distance!} m</Box>}
        </Stack>
      </Stack>
    </ListItemButton>;
  }

  return (
    <BottomSheet
      backdrop
      disableClose
      detent={"full-height"}
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpenStart={() => {
        input.current!.select();
        input.current!.focus();
      }}
    >
      <Stack spacing={"0.5em"} padding={"0.25em"}>
        <TextField
          // defaultValue={text}
          type={"search"}
          autoComplete={"none"}
          inputRef={input}
          onChange={(e) => {
            setResults([]);
            debounced(e.target.value);
          }}
          placeholder={placeholder}
          size={"small"}
        />
        <ListItemButton onClick={() => {
          const location = mapApi.currentLocation();
          geocoder.geocode({ location: new google.maps.LatLng(location) }, (result) => {
            onSelection({
              name: 'Current location',
              address: result?.[0]?.formatted_address || '',
              type: LocationType.GEO_CODE,
              value: `${location.lat}, ${location.lng}`,
              variant: 'current'
            });
            setOpen(false);
          })
        }}>
          <Stack sx={{ color: blue[500] }} direction={"row"} spacing={"0.5em"}><NearMe/><Box>Current
            location</Box></Stack>
        </ListItemButton>
        {text
          ? <List disablePadding>
            <ListSubheader component="div" id="nested-list-subheader">
              Results
            </ListSubheader>
            {results.map(renderResult)}
          </List>
          : <>
            <Divider/>
            <List disablePadding>
              <ListSubheader component="div" id="nested-list-subheader">
                Favourites
              </ListSubheader>
              <Stack direction={"row"} spacing={"0.5em"} overflow={"auto"}>
                {favourites.filter(loc => loc.variant === 'favourite').map((loc) => (
                  <Box key={loc.value!}>
                    <Button
                      variant={"contained"}
                      color={"inherit"}
                      sx={{ borderRadius: "2em", boxShadow: "none" }}
                      onClick={() => {
                        onSelection(loc);
                        setOpen(false);
                      }}
                    >
                      {loc.name}
                    </Button>
                  </Box>
                ))}
              </Stack>
            </List>
            <Divider/>
            <List disablePadding>
              <ListSubheader component="div" id="nested-list-subheader">
                Suggestions
              </ListSubheader>
              {favourites.filter(loc => loc.variant === 'suggestion').map(renderResult)}
            </List>
          </>
        }
      </Stack>
    </BottomSheet>
  );
};
export default LocationSearch;