import * as React from 'react';
import Button from '@mui/material/Button';
import { Fare, Gateway, Leg, Order, OrderItem, OrderStatus, Ticket, Trip } from "../api/models/Vivi";
import TicketButton from "./TicketButton";
import { useNavigate } from "react-router-dom";
import apiFor from "../api/Api";
import { Apis } from "../api/Config";
import { useToken } from "../lib/context/TokenContext";
import { useUser } from "../lib/context/UserContext";

const TripCTAButton = (
  {
    trip,
    leg,
    swipe,
    onPurchased,
  }: {
    trip: Trip,
    leg?: Leg,
    swipe?: boolean
    onPurchased: () => void
  }
) => {
  const token = useToken();
  const user = useUser();
  const navigate = useNavigate();
  const orderApi = apiFor(Apis.Vivi.Order, { token });

  const filteredOrders = (trip.orders as Order[])?.filter(o =>
      !leg || (o.orderItems as OrderItem[])?.find(item =>
        (item.fare as Fare).legs?.find(l =>
          ((l as Leg).id || l) === leg.id
        )
      )
  );
  const hasCompletedOrders = !!filteredOrders?.find(o => o.status === OrderStatus.COMPLETE);
  const pendingOrder = filteredOrders?.find(o => o.status === OrderStatus.PENDING);
  const tickets = (trip.orders as Order[])?.reduce((a, o) =>
      a.concat((o.orderItems as OrderItem[])?.reduce((b, i) =>
          b.concat(i.tickets as Ticket[]),
        [] as Ticket[])),
    [] as Ticket[]);
  const returnType = window.location.hash.replaceAll(/#\/([^/?]+).*/g, '$1');

  return (
    <>
      {hasCompletedOrders &&
        <TicketButton
          initial={leg ? tickets?.findIndex((t) => t.legs?.indexOf(leg.id!) !== -1) : 0}
          tickets={tickets.map(t => ({
              ...t,
              legs: (t.legs as string[]).map(lid => (trip.legs as Leg[]).find(l => l.id === lid)),
            } as Ticket)
          )}
        />
      }
      {!hasCompletedOrders && pendingOrder &&
        <>
          <Button
            variant={"contained"}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/orders/${pendingOrder.id}?return=${returnType}`);
            }}>
            Complete purchase
          </Button>
        </>
      }
      {!hasCompletedOrders && !pendingOrder && !!trip?.fares?.length &&
        <Button
          variant={"contained"}
          onClick={(e) => {
            e.stopPropagation();
            const fare = (trip.fares as Fare[])?.reduce((a, b) => (a.price || Number.MAX_VALUE) < (b.price || Number.MAX_VALUE) ? a : b);
            orderApi.create({
              obj: {
                gateway: Gateway.BRAINTREE,
                customer: user.email
                  ? {
                    email: user.email,
                  }
                  : undefined,
                trip: trip.id,
                orderItems: [
                  {
                    fare: fare?.id
                  }
                ],
              }, fields: [ 'id', 'trip.fares.*' ]
            }).then((o) => navigate(`/orders/${o.id}?return=${returnType}&swipe=${swipe}`))
          }}>
          {swipe ? 'Swipe to buy' : 'Buy'}
        </Button>
      }
      {trip?.fares?.length === 0 && <Button disabled variant={"contained"}>No tickets available</Button>}
    </>
  );
}
export default TripCTAButton;
