import { blue, grey } from "@mui/material/colors";
// google.maps.SymbolPath.CIRCLE
export const OriginIcon = {
  path: 0.0,//google.maps.SymbolPath.CIRCLE,
  fillColor: grey[900],
  fillOpacity: 1,
  strokeColor: blue[100],
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 7
};

export const DestinationIcon = {
  path: 0.0,//google.maps.SymbolPath.CIRCLE,
  fillColor: grey[100],
  fillOpacity: 1,
  strokeColor: grey[900],
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 7
};

export const CurrentLocationIcon = {
  path: 0.0,//google.maps.SymbolPath.CIRCLE,
  fillColor: blue[400],
  fillOpacity: 1,
  strokeColor: blue[100],
  strokeOpacity: 0.9,
  strokeWeight: 2,
  scale: 7
};

export const HeadingIcon = {
  path: 2.0,//google.maps.SymbolPath.CIRCLE,
  fillColor: blue[200],
  fillOpacity: 1,
  strokeColor: blue[100],
  strokeOpacity: 0.9,
  strokeWeight: 2,
  scale: 7
};

