import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'

const StripeProvider = ({
  children,
  stripePubKey,
}: {
  children: React.ReactNode
  stripePubKey: string
}) => {
  const [stripePromise] = useState(() => loadStripe(stripePubKey))
  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeProvider
