import React, { useEffect, useMemo, useRef, useState } from 'react'
import BottomSheet from "../components/BottomSheet";
import { Box, CircularProgress, IconButton, Link, Stack } from "@mui/material";
import { Apis } from "../api/Config";
import TripSummary from "../components/TripSummary";
import { CallingPoint, Leg, LocationType, Rating, TransportType, Trip, ViviLocation } from "../api/models/Vivi";
import { useLocation, useNavigate } from "react-router-dom";
import apiFor from "../api/Api";
import { useEventBus } from "../lib/EventBus";
import SearchSummary from "../components/SearchSummary";
import { MarkerType } from "../lib/MapApi";
import { blue } from "@mui/material/colors";
import { useUser } from "../lib/context/UserContext";
import { useToken } from "../lib/context/TokenContext";
import { useGoogleApis } from "../lib/context/GoogleApisContext";
import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { convertTimeZone, toISOString } from "../lib/DateTime";

const SuggestionPage = () => {
  const eventBus = useEventBus();
  const token = useToken();
  const user = useUser();
  const { mapApi } = useGoogleApis();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [ trips, setTrips ] = useState<Trip[]>([]);
  const [ timeOfRating, setTimeOfRating ] = useState(new Date().getTime());
  const [ loading, setLoading ] = useState(false);
  const [ suggestionLoading, setSuggestionLoading ] = useState(true);
  const [ rating, setRating ] = useState<string[]>([]);

  const journeyApi = apiFor(Apis.Vivi.Journey, { token });
  const suggestionApi = apiFor(Apis.Vivi.Suggestion, { token });

  const { search } = useLocation();
  const params = new URLSearchParams(search)
  const selected = params.get('selected') || '';

  const plot = (trip: Trip) => {
    mapApi.clear();
    mapApi.addMarker({
        lat: +(trip.from as ViviLocation)!.value!.split(',')[0],
        lng: +(trip.from as ViviLocation)!.value!.split(',')[1]
      },
      MarkerType.Origin
    );
    mapApi.addMarker({
        lat: +(trip.to as ViviLocation)!.value!.split(',')[0],
        lng: +(trip.to as ViviLocation)!.value!.split(',')[1]
      },
      MarkerType.Destination
    );
    (trip.legs as Leg[])?.forEach((leg) => {
      mapApi.addPolyline({
          id: leg.id!,
          path: leg.polyline!.split(';').map((p) => {
            const pp = p.split(',');
            return { lat: +pp[0], lng: +pp[1] }
          })
        },
        blue[600]
      );
    });
  };

  useMemo(() => {
    suggestionApi.list({
      fields: [
        'scheduleDetails',
        'journeyConfidence',
        'journeyId',
        'from.*',
        'to.*',
        'mode',
        'date',
        'duration',
        'changes',
        'depart',
        'arrive',
        'follow',
        'source',
        'legs.*',
        'legs.from.name',
        'legs.to.name',
        'legs.stops.*',
        'legs.stops.location.*',
        'legs.service.*',
        'fares.name',
        'fares.price',
        'legs.ticket',
        'orders.status',
        'orders.orderItems.tickets.*',
        'orders.orderItems.tickets.fare.name',
        'orders.orderItems.tickets.fare.from.name',
        'orders.orderItems.tickets.fare.to.name'
      ],
      params: {
        location: mapApi.currentLocation().simulated ? '' : `${mapApi.currentLocation().lat},${mapApi.currentLocation().lng}`
      }
    }).then((tripPage) => {
      if (tripPage.content.length === 0) {
        return navigate('/search');
      }

      let newTrips: Trip[] = [];
      if (trips.length) {
        trips.forEach((trip) => {
          newTrips.push(tripPage.content.find((t) => t.id === trip.id)!);
        });
        newTrips = newTrips.filter((t) => !!t);
      } else {
        newTrips = tripPage?.content.reverse()
      }

      const selectedIdx = newTrips.findIndex(t => t.id === selected);
      if (selected && selectedIdx !== -1) {
        swap(selectedIdx, newTrips);
      } else {
        setTrips(newTrips);
        setSuggestionLoading(false);
        plot(newTrips[newTrips.length - 1]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ timeOfRating ]);

  useMemo(() => {
    eventBus.on('trip-changed', () => {
      setTimeOfRating(new Date().getTime())
    })
  }, [ eventBus ]);

  trips.forEach(trip => {
    if (!(trip.legs![0] as Leg).scheduledDepart) {
      (trip.legs![0] as Leg).scheduledDepart = trip.depart;
      (trip.legs![0] as Leg).scheduledArrive = (trip.legs![1] as Leg).scheduledDepart;
      (trip.legs![trip.legs!.length - 1] as Leg).scheduledArrive = trip.arrive;
      (trip.legs![trip.legs!.length - 1] as Leg).scheduledDepart = (trip.legs![trip.legs!.length - 2] as Leg).scheduledArrive;
    }
  })

  const simulate = () => {
    console.log('simulate', trips)
    const localTrips = trips.concat([]);
    const trip = localTrips.splice(trips.length - 1, 1)[0];
    const leg = (trip.legs as Leg[]).find(l => l.mode === TransportType.RAIL);
    const station = (leg?.stops?.[Math.floor(leg?.stops?.length / 2)] as CallingPoint)?.location as ViviLocation | null;
    (trip.metadata as { message: string }) = { message: `Burst pipe at ${station?.name}` };
    localTrips.push(trip);
    setTrips(localTrips);
    plot(localTrips[localTrips.length - 1]);
  }

  const reRoute = (trip: Trip) => {
    setLoading(true);
    const leg = (trip.legs as Leg[]).find(l => l.mode === TransportType.RAIL);
    journeyApi.get({
      id: trip.journeyId,
      fields: [ 'from.*', 'to.*', 'mode', 'date', 'via', 'avoid' ]
    }).then((original) => {
      const newJourney = {
        from: original.from,
        to: original.to,
        mode: original.mode,
        date: original.date,
        via: original.via,
        avoid: (original.avoid || []).concat(((leg?.stops?.[Math.floor(leg?.stops?.length / 2)] as CallingPoint)?.location as ViviLocation)?.value || '')
      };
      journeyApi.create({ obj: newJourney, fields: [ 'id' ] }).then((j) => {
        navigate(`/results/${j.id}?replace=${trip.id}`);
      });
    });
  }

  useMemo(() => {
    // feature detect
    let deviceMotionEvent = window.DeviceMotionEvent as any;
    if (typeof deviceMotionEvent?.requestPermission === 'function') {
      deviceMotionEvent.requestPermission()
        .then((permissionState: any) => {
          if (permissionState === 'granted') {
            window.addEventListener('devicemotion', (e) => {
              if (Math.abs(e.accelerationIncludingGravity?.x!) > 30 || Math.abs(e.accelerationIncludingGravity?.y!) > 30) {
                eventBus.dispatch('trip-disruption-simulation');
              }
            });
            window.addEventListener('deviceorientation', (e) => {
              // mapApi.setHeading((e as unknown as { webkitCompassHeading: number }).webkitCompassHeading)
            });
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus ]);

  const sim = useRef<() => void>()
  useEffect(() => {
    if (eventBus) {
      if (sim.current) {
        sim.current();
      }
      sim.current = eventBus.effect('trip-disruption-simulation', simulate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ eventBus, trips ]);


  const swap = (idx: number, newTrips?: Trip[]) => {
    const localTrips = newTrips || (trips).concat([]);
    const trip = localTrips.splice(idx, 1)[0];
    localTrips.push(trip);
    setTrips(localTrips);
    plot(localTrips[localTrips.length - 1]);
  }

  let paddings = [ '8.5em', '4.5em', 'inherit' ];
  if (trips.length !== 3) {
    paddings.splice(0, 3 - trips.length);
  }

  return (
    <Box>
      {suggestionLoading && (
        <BottomSheet disableDrag disableClose disableBack>
          <Stack
            sx={{ padding: '0.5em', minHeight: '16em' }}
            spacing={"1em"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={"2em"}/>
            <Box>Doin' our AI magic, please hold!</Box>
          </Stack>
        </BottomSheet>
      )}
      {trips.length > 0 && trips.map((trip, idx) => (
        <BottomSheet key={idx} disableDrag disableClose disableBack>
          <Box sx={{ padding: '0.5em', paddingBottom: paddings[idx] }}>
            <Stack spacing={'1em'}>
              <SearchSummary
                disableEdit={idx !== (trips.length - 1)}
                journey={{
                  id: trip.journeyId,
                  from: trip.from,
                  to: trip.to,
                  mode: trip.mode,
                  date: trip.date,
                  source: trip.source,
                  confidence: trip.journeyConfidence
                }}
                onClick={() => idx !== (trips.length - 1) ? swap(idx) : null}
              />
              {rating.indexOf(trip.id!) === -1 &&
                <Box sx={{ position: "absolute", top: '-1em', right: 0 }}>
                  <IconButton
                    onClick={() => {
                      apiFor(Apis.Vivi.Feedback, { token, journey: trip.journeyId }).create({
                        obj: {
                          timeOfRating: toISOString(convertTimeZone({ date: new Date(timeOfRating), to: 'UTC' })),
                          location: {
                            type: LocationType.GEO_CODE,
                            value: `${mapApi.currentLocation().lat},${mapApi.currentLocation().lng}`
                          },
                          rating: Rating.GOOD,
                        }
                      }).then(() => {
                        enqueueSnackbar({
                          variant: "default",
                          message: ("Thank you for your feedback")
                        })
                        setRating([ ...rating, trip.id! ]);
                      })
                    }}
                  ><ThumbUp color={"disabled"}/></IconButton>
                  <IconButton
                    onClick={() => {
                      apiFor(Apis.Vivi.Feedback, { token, journey: trip.journeyId }).create({
                        obj: {
                          timeOfRating: toISOString(convertTimeZone({ date: new Date(timeOfRating), to: 'UTC' })),
                          location: {
                            type: LocationType.GEO_CODE,
                            value: `${mapApi.currentLocation().lat},${mapApi.currentLocation().lng}`
                          },
                          rating: Rating.BAD,
                        }
                      }).then(() => {
                        const localTrips = trips.concat([])
                        localTrips.splice(idx, 1);
                        enqueueSnackbar({
                          variant: "default",
                          message: ("Thank you for your feedback")
                        })
                        if (localTrips.length) {
                          swap(localTrips.length - 1, localTrips);
                        } else {
                          return navigate('/search');
                        }
                      });
                    }}
                  ><ThumbDown color={"disabled"}/></IconButton>
                </Box>
              }
              {!!trip.metadata &&
                <Stack
                  sx={{
                    width: "100%",
                    padding: "1em",
                    boxSizing: "border-box",
                    backgroundColor: "#ffc2c2",
                    borderRadius: "1em"
                  }}
                  direction={"row"}
                  spacing={"1em"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>{(trip.metadata as { message: string }).message}</Box>
                  <Link
                    sx={{ textWrap: "nowrap" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!loading)
                        reRoute(trip);
                    }}>
                    {loading
                      ? <CircularProgress size={"1em"}/>
                      : 'Re-route'
                    }
                  </Link>
                </Stack>
              }
              <TripSummary trip={trip} swipe={!!user.defaultPaymentMethod}/>
              <Box sx={{ height: '0.5em' }}></Box>
            </Stack>
          </Box>
        </BottomSheet>
      ))}
    </Box>
  )
};
export default SuggestionPage;
