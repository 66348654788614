import React from "react";
import PickerColumn, { PickerOption } from "./PickerColumn";
import { styled } from "@mui/material";

const Container = styled('div')`
  z-index: 10001;
  width: 100%;
  mask-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);

  &, *, *:before, *:after {
    box-sizing: border-box;;
  }

  .picker-inner {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 20px;

    font-size: 1.2em;
  }

  .picker-column {
    flex-grow: 1;

    position: relative;

    max-height: 100%;

    overflow: hidden;
    text-align: center;

    .picker-scroller {
      transition: 300ms;
      transition-timing-function: ease-out;
    }

    .picker-item {
      position: relative;

      padding: 0 10px;

      white-space: nowrap;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;

      &.picker-item-selected {
        color: #222;
      }
    }
  }

  .picker-highlight {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;

    pointer-events: none;

    &:before, &:after {
      content: ' ';
      position: absolute;
      left: 0;
      right: auto;

      display: block;
      width: 100%;
      height: 1px;

      background-color: #d9d9d9;
      transform: scaleY(0.5);
    }

    &:before {
      top: 0;
      bottom: auto;
    }

    &:after {
      bottom: 0;
      top: auto;
    }
  }
`;

const Picker = (
  {
    optionGroups,
    valueGroups,
    onChange,
    onClick = () => null,
    itemHeight = 36,
    height = 216,
    wheel = 'off'
  }:
    {
      optionGroups: { [_: string]: PickerOption[] },
      valueGroups: { [_: string]: PickerOption },
      onChange: (name: string, value: PickerOption) => void,
      onClick?: (name: string, value: PickerOption) => void,
      itemHeight?: number,
      height?: number,
      wheel?: 'off' | 'natural' | 'normal'
    }
) => {
  const highlightStyle = {
    height: itemHeight,
    marginTop: -(itemHeight / 2)
  };

  return (
    <Container style={{ height }}>
      <div className="picker-inner">
        {Object.keys(optionGroups).map((name) =>
          <PickerColumn
            key={name}
            name={name}
            options={optionGroups[name]}
            initialValue={valueGroups[name]}
            itemHeight={itemHeight}
            columnHeight={height}
            onChange={onChange}
            onClick={onClick}
            wheel={wheel}
          />
        )}
        <div className="picker-highlight" style={highlightStyle}></div>
      </div>
    </Container>
  );
}

export default Picker;