import { Model, UUID } from './Base';

export enum LocationType {
  GEO_CODE = 'GEO_CODE',
  STOP_CODE = 'STOP_CODE',
  STOP_UID = 'STOP_UID',
  UNKNOW = 'UNKNOW'
}

export enum TravelMode {
  DEPART = 'DEPART',
  ARRIVE = 'ARRIVE',
}

export enum TransportType {
  WALK = 'WALK',
  UNDERGROUND = 'UNDERGROUND',
  RAIL = 'RAIL',
  TRAM = 'TRAM',
  BUS = 'BUS',
  REPLACEMENT_BUS = 'REPLACEMENT_BUS',
  COACH = 'COACH',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum ChargeType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export enum Direction {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
}

export enum Frequency {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum Gateway {
  STRIPE = 'STRIPE',
  BRAINTREE = 'BRAINTREE',
}

export enum DeprecationType {
  NONE = 'NONE',
  SOFT = 'SOFT',
  HARD = 'HARD',
}

export enum AlertType {
  WARNING = 'WARNING',
  DOWNTIME = 'DOWNTIME',
}

export enum VersionType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  ToS = 'ToS',
}

export enum FavouriteVariant {
  STORED = 'STORED',
  SUGGESTION = 'SUGGESTION',
}

export enum DeviceType {
  APNS = 'APNS',
  GCM = 'GCM'
}

export enum Source {
  USER = 'USER',
  SCHEDULE = 'SCHEDULE',
  AI = 'AI',
}

export enum Rating {
  GOOD = 'GOOD',
  BAD = 'BAD',
}

export enum PassType {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export type Info = {
  deprecation?: DeprecationType
  needsToSAcceptance?: boolean
  alerts?: (Alert | string)[]
  versions?: (Version | string)[]
} & Model;

export type Alert = {
  start?: string
  end?: string
  type?: AlertType
  message?: string
} & Model

export type Version = {
  type?: VersionType
  semVer?: string
} & Model

export type User = {
  name?: string
  acceptedTos?: string
  email?: string
  processingAllowed?: boolean
  railCards?: (string | RailCard)[]
  defaultPaymentMethod?: string
  paymentMethods?: (PaymentMethod | string)[]
  commute?: Route | string
  routes?: (Route | string)[]
  favourites?: (Favourite | string)[]
} & Model;

export type Favourite = {
  name?: string
  address?: string
  type?: LocationType
  value?: string
  variant?: FavouriteVariant
} & Model;

export type ViviLocation = {
  name?: string
  address?: string
  type?: LocationType
  value?: string
} & Model;

export type Fare = {
  name?: string
  price?: number
  from?: ViviLocation | string
  to?: ViviLocation | string
  legs?: (Leg | string)[]
} & Model;

export type Leg = {
  from?: CallingPoint | string
  scheduledDepart?: string
  depart?: string
  to?: CallingPoint | string
  scheduledArrive?: string
  arrive?: string
  duration?: number
  mode?: TransportType
  stops?: (CallingPoint | string)[]
  ticket?: Ticket | string
  polyline?: string
} & Model;

export type CallingPoint = {
  location?: ViviLocation | string
  scheduledArrive?: string
  arrive?: string
  scheduledDepart?: string
  depart?: string
} & Model

export type Trip = {
  journeyId?: UUID
  from?: ViviLocation | string
  to?: ViviLocation | string
  mode?: TravelMode
  date?: string
  depart?: string
  arrive?: string
  changes?: number
  duration?: number
  follow?: boolean
  legs?: (Leg | string)[]
  fares?: (Fare | string)[]
  orders?: (Order | string)[]
  source?: Source
  confidence?: number
  journeyConfidence?: number
} & Model;

export type Feedback = {
  timeOfRating?: string
  location?: ViviLocation | string
  rating: Rating
} & Model

export type Journey = {
  from?: ViviLocation | string
  to?: ViviLocation | string
  mode?: TravelMode
  date?: string
  trips?: (Trip | string)[]
  via?: string[]
  avoid?: string[]
  source?: Source
  confidence?: number
  feedback?: Feedback
} & Model;

export type Order = {
  trip?: Trip | string
  reference?: string
  externalReference?: string
  gateway?: Gateway
  status?: OrderStatus
  customer?: Customer | string
  orderItems?: (OrderItem | string)[]
  total?: number
  billed?: number
  paid?: number
  transactions?: (Transaction | string)[]
  paymentMethod?: PaymentMethod | string
} & Model;

export type OrderItem = {
  order?: Order | string
  externalReference?: string
  fare?: Fare | string
  tickets?: (Ticket | string)[]
} & Model

export type Ticket = {
  orderItem?: OrderItem | string
  legs?: (Leg | string)[]
  reference?: string
  mimeType?: string
  binary?: string
  coach?: string
  number?: string
  seatAttributes?: string[]
} & Model;

export type Customer = {
  name?: string
  email?: string
  address?: Address | string
} & Model;

export type Address = {
  line1?: string
  line2?: string
  city?: string
  postalCode?: string
  state?: string
  country?: string

} & Model;

export type Transaction = {
  amount: number
  currency: string
  type: ChargeType
  status: TransactionStatus
} & Model;

export type PaymentDescriptor = {
  brand?: string
  display?: string
} & Model;

export type PaymentMethod = {
  descriptor: PaymentDescriptor
} & Model;

export type Route = {
  from?: Favourite | string
  to?: Favourite | string
  mode?: TravelMode
  schedules?: (Schedule | string)[]
} & Model;

export type Schedule = {
  direction?: Direction
  mode?: TravelMode
  frequency?: Frequency
  start?: string
  end?: string
  cadence?: number
  values?: number[]
  time?: string
} & Model;

export type RailCard = {
  name?: string
} & Model

export type Device = {
  description?: string
  user?: UUID
  token?: string
  type?: DeviceType
} & Model

export type WalletPass = {
  pass?: string
  type?: PassType
} & Model