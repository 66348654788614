import React, { useEffect, useState } from 'react'
import Sheet from "react-modal-sheet";
import { useNavigate } from "react-router-dom";
import { SheetProps } from "react-modal-sheet/src/types";
import { ArrowBack, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";


function BottomSheet(props: {
  isOpen?: boolean
  onClose?: () => void
  disableClose?: boolean
  disableBack?: boolean
  backdrop?: boolean
} & Pick<SheetProps, 'children' | 'detent' | 'snapPoints' | 'disableDrag' | 'onOpenStart' | 'onOpenEnd' | 'style' | 'draggable' | 'initialSnap'| 'onClick'>) {
  const navigate = useNavigate();
  const [ open, setOpen ] = useState(props.isOpen);

  useEffect(() => setOpen(true), []);
  const disableDrag = !!props.disableDrag;
  const disableClose = !!props.disableClose;
  const disableBack = !!props.disableBack;
  const backdrop = !!props.backdrop;

  props = { ...props };

  delete props.disableClose;
  delete props.disableBack;
  delete props.backdrop;

  return (
    <Sheet
      detent={"content-height"}
      isOpen={!!open}
      onClose={() => {
        if (props.onClose) props.onClose();
        setOpen(false);
      }}
      onCloseEnd={() => !disableClose && navigate("/")}
      {...props}
    >
      <Sheet.Container style={{ paddingBottom: "1em" }}>
        {(!disableDrag || !disableBack || !disableClose) && <Sheet.Header/>}
        <Sheet.Content>
          {disableClose && !disableBack &&
              <IconButton
                  style={{ position: "absolute", top: "-2em", left: "0.5em" }}
                  size={"small"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (props.onClose) props.onClose();
                    setOpen(false);
                  }}
              >
                  <ArrowBack/>
              </IconButton>
          }
          {!disableClose &&
              <IconButton
                  style={{ position: "absolute", top: "-2em", right: "0.5em" }}
                  size={"small"}
                  onClick={() => {
                    if (props.onClose) props.onClose();
                    setOpen(false);
                  }}>
                  <Close/>
              </IconButton>
          }
          {props.children}
        </Sheet.Content>
      </Sheet.Container>
      {backdrop ? <Sheet.Backdrop/> : <></>}
    </Sheet>
  );
}

export default BottomSheet;
