import { useApiList } from "../../api/ApiHooks";
import { Apis } from "../../api/Config";
import { CircularProgress, Stack } from "@mui/material";
import React from "react";
import { Operation, SortDirection } from "../../api/Search";
import SearchSummary from "../SearchSummary";
import { useToken } from "../../lib/context/TokenContext";

const SearchHistory = () => {
  const token = useToken();
  const { data: journeysPage, loading } = useApiList(Apis.Vivi.Journey, { token }, {
    pageSize: 50,
    fields: [ 'source', 'from.*', 'to.*', 'mode', 'date', 'createdAt' ],
    search: [ { field: "source", op: Operation.Equals, value: 'USER' } ],
    sort: [
      { field: 'createdAt', direction: SortDirection.Desc }
    ]
  });
  return loading
    ? (
      <Stack alignItems={"center"} justifyContent={"center"}>
        <CircularProgress/>
      </Stack>
    )
    : <Stack spacing={"1em"} overflow={"auto"} maxHeight={"86vh"}>
      {journeysPage?.content?.map((journey) => (
        <SearchSummary key={journey.id!} journey={journey} view/>
      ))}
    </Stack>
};

export default SearchHistory;